import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Modal, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { Scrollbars } from "react-custom-scrollbars";
import DefaultProductImage from "../../../assets/images/no-image.svg";
import "../../style.css";
import "./styles.css";
import data from "../../../constants/retailPos.json";
import locale from "antd/lib/date-picker/locale/en_US";
import tick from "../../../assets/images/tic.png";

const ProductPanel = (props) => {
  const {
    checkIsManualWeight,
    productsList,
    isProductsFilter,
    getMoreProducts,
    productListCardRef,
    selectProductInCart,
    productItems,
    setProductItems,
    productsDisplay,
    setIsProductsVisible,
    pickProduct,
    selectProductToCart,
  } = props;
  const [displayBatchSelection, setDisplayBatchSelection] = useState(false);
  const [batchSetAvailable, setBatchSetAvailable] = useState([]);
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  // const pickProduct = (obj) => {
  //   setIsProductsVisible(false);
  //   if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
  //     if (obj.mBatch.length === 1) {
  //       addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
  //     } else {
  //       const localObj = obj;
  //       for (let i = 0; i < obj.mBatch.length; i += 1) {
  //         const batchItem = { ...localObj.mBatch[i] };
  //         const obj = { ...localObj };
  //         if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
  //           // prettier-ignore
  //           const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
  //           const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
  //           batchItem.price = taxedPrice;
  //           obj.cTaxId = obj.contraTaxId;
  //           obj.taxRate = obj.contraRate;
  //         }
  //       }
  //     }
  //   } else {
  //     let array = [...productsList];
  //     array.map((item) => {
  //       if (obj.mProductId === item.mProductId) {
  //         item.selected = "Y";
  //       }
  //       else {
  //         item.selected = "N";
  //       }
  //     });
  //     addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
  //   }
  // };
  // const addDefinedProduct = (productObjs, upc, batchno, mBatchId, price) => {
  //   const productObj = { ...productObjs };
  //   if (productObj.overRideTax === "Y" && price <= productObj.overRideCondition) {
  //     // prettier-ignore
  //     const originalPrice = price - (price - (price * (100 / (100 + productObj.taxRate))));
  //     const taxedPrice = originalPrice + (originalPrice * productObj.contraRate) / 100;
  //     price = taxedPrice;
  //     productObj.cTaxId = productObj.contraTaxId;
  //     productObj.taxRate = productObj.contraRate;
  //   }
  //   const productDefined = {
  //     batchno: batchno,
  //     description: productObj.description,
  //     discount: 0,
  //     discountName: "",
  //     imageurl: productObj.imageurl,
  //     isDecimal: productObj.isDecimal,
  //     isManualQty: productObj.isManualQty,
  //     isPromoApplicable: productObj.isPromoApplicable,
  //     isReturn: false,
  //     mBatchId: mBatchId,
  //     mPricingruleId: null,
  //     name: productObj.name,
  //     nettotal: 0,
  //     primaryOrderLine: null,
  //     productId: productObj.mProductId,
  //     realPrice: price,
  //     returnQty: null,
  //     salePrice: price,
  //     stock: productObj.onhandQty,
  //     tax: productObj.cTaxId,
  //     taxAmount: 0,
  //     taxRate: productObj.taxRate,
  //     uom: productObj.csUomId,
  //     uom_name: productObj.uomName,
  //     upc: upc,
  //     value: productObj.value,
  //     weight: 0,
  //     shortDescription: productObj.shortDescription,
  //     hsncode: productObj.hsncode,
  //     csBunitId: productObj.csBunitId,
  //     mProductCategoryId: productObj.mProductCategoryId,
  //     productManufacturerId: productObj.productManufacturerId,
  //     productBrandId: productObj.productBrandId,
  //     batchedProduct: productObj.batchedProduct,
  //     batchedForSale: productObj.batchedForSale,
  //     batchedForStock: productObj.batchedForStock,
  //     multiPrice: productObj.multiPrice,
  //     shelfLife: productObj.shelfLife,
  //   };
  //   checkIsManualWeight(productDefined);
  //   selectProductInCart(productDefined);
  // };

  // const selectProductToCart = (data) => {
  //   checkIsManualWeight(data);
  //   setDisplayBatchSelection(false);
  // };
  
  let cardDetails = {
    width: props.productsList.length === 1 ? "15vw" : "100%",
    height: data.posScreenStyles.productsCard.cardDetails.height,
    background: data.posScreenStyles.productsCard.cardDetails.background,
    borderRadius: data.posScreenStyles.productsCard.cardDetails.borderRadius,
    cursor: "pointer",
  };

  let kioscCartDetails = {
    width: "100%",
    height: data.posScreenStyles.productsCard.cardDetails.height,
    background: data.posScreenStyles.productsCard.cardDetails.background,
    borderRadius: data.posScreenStyles.productsCard.cardDetails.borderRadius,
    cursor: "pointer",
  };

  let superMarketCartDetails = {
    width: "101%",
    height: data.posScreenStyles.productsCard.cardDetails.height,
    background: data.posScreenStyles.productsCard.cardDetails.background,
    borderRadius: data.posScreenStyles.productsCard.cardDetails.borderRadius,
    cursor: "pointer",
  };

  let cardDetailsWithoutImg = {
    width: props.productsList.length === 1 ? "15vw" : "100%",
    height: "9.8vh",
    background: data.posScreenStyles.productsCard.cardDetails.background,
    borderRadius: "7px",
    border: data.posScreenStyles.productsCard.cardDetails.border,
    cursor: "pointer",
    // padding:"0.3vw",
  };

  const col = [];
  const productsCount = productsList.length;

  const focusFirstElement = (i) => {
    /* if (i + 1 === productsCount && productsCount !== 1) {
       setTimeout(() => {
         if (productListCardRef.current) {
           productListCardRef.current.focus();
         }
       }, 100);
     }*/
  };

  const handleKey = (e) => {
    const { keyCode } = e;
    if (keyCode === 27) {
      setDisplayBatchSelection((b) => {
        if (b) {
          return !b;
        } else {
          return b;
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  return (
    <React.Fragment>
      {productsDisplay === "super-market" ? (
        <Scrollbars autoHide className="productScroll1">
              {productsList.map((obj, i) => (
            <Row gutter={[15, 11]} style={{ width: "99%" ,backgroundColor:"#fff",marginTop:"1.5vh",height: "11vh",marginLeft:"-0.1vw",borderRadius:"10px"}}>
                <Col span={4}>
                <img
                            onError={setDefaultImage}
                            style={{
                              width: "100%",
                              height: "10vh",
                              objectFit: "contain",
                              borderRadius: "5px",
                              paddingTop:"1vh",
                              alignSelf:"center"
                            }}
                            src={obj.imageurl || DefaultProductImage}
                            alt=""
                          />
                </Col>
                <Col span={16}>
                <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                    <label style={{ ...data.posScreenStyles.productsCard.cardDetails.productDescr, whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className="productName">
                      {obj["description"]}
                    </label>
                  </div>
                 <p style={data.posScreenStyles.productsCard.cardDetails.superMarketProd} className="productName">
                  {obj["name"]}
                  <span style={{display:"block"}}>{obj["value"]}</span>
                  </p>
                </Col>
                <Col span={4} style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
                <span className="productDescription-kiosk" title={obj["description"]}>
                            {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                              {obj.sunitprice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}/{obj["uomName"]}
                          </span>
                <span onClick={() => {pickProduct(obj)}} style={{fontSize:"1.5vh",color:"#fff",backgroundColor:"#2F3856",padding:"1vh 1.7vw",marginTop:"2%",borderRadius:"3px",cursor:"pointer"}}>ADD</span>

                </Col>
              <span tabIndex={0}></span>
            </Row>
              ))}

        </Scrollbars>
      ) : productsDisplay === "kiosk" ? (
        <Scrollbars autoHide className="productScrollKiosk">
          <div style={{ width: "100%", padding: "1.5% 0 0 2%" }}>
            <Row gutter={[16, 16]} style={{ width: "100%" }}>
            {productsList.slice(0, 200).map((obj, i) => (
                <Col key={`${obj.mProductId}${obj.name}`} span={4}>
                  {posConfig.showImage === "Y" ? (
                    <div
                      ref={i === 0 ? productListCardRef : null}
                      id={`productCardItem-${i}`}
                      onBlur={() => focusFirstElement(i)}
                      tabIndex={0}
                      onKeyPress={(e) => (e.charCode === 13 ? pickProduct(obj) : null)}
                      onClick={() => {pickProduct(obj)
                      }}
                      style={kioscCartDetails}
                    >
                      <Row>
                        <Col span={24} style={{ textAlign: "center", height: "11vh" }}>
                          <img
                            onError={setDefaultImage}
                            style={{
                              width: "100%",
                              height: "11vh",
                              objectFit: "contain",
                              borderRadius: "5px",
                              background: obj.selected === "Y" ? "rgba(0, 0, 0, 0.44)" : "",
                            }}
                            src={obj.imageurl || DefaultProductImage}
                            alt=""
                          />
                          {obj.selected === "N" ? (
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "rgba(0, 0, 0, 0.44)",
                                borderRadius: "5px",
                              }}
                            >
                              {/*<img src={tick} style={{ alignSelf: "center", height: "3vh" }} />*/}

                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: "1.4vh", height: "3vh" }}>
                        <Col
                          span={14}
                          offset={1}
                          style={{
                            maxWidth: "65% !important",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            position: "relative",
                            objectFit: "cover",
                            height: "3vh",
                          }}
                        >
                          <label title={obj["name"]} style={data.posScreenStyles.productsCard.cardDetails.prodName} className="productName">
                            {obj["name"]}
                          </label>
                        </Col>
                        <Col span={8} style={{ textAlign: "right", height: "3vh" }}>
                          <label style={data.posScreenStyles.productsCard.cardDetails.productPrice}>
                            {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}&nbsp;
                            <span>
                              {obj.sunitprice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                            </span>
                          </label>
                        </Col>
                      </Row>
                      <Row style={{ height: "4vh", paddingTop: "1.2vh" }}>
                        <Col span={23} offset={1} style={{ lineHeight: "1" }}>
                          <span className="productDescription-kiosk" title={obj["description"]}>
                            {obj["uomName"]}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div
                      ref={i === 0 ? productListCardRef : null}
                      id={`productCardItem-${i}`}
                      onBlur={() => focusFirstElement(i)}
                      tabIndex={0}
                      bodyStyle={{ padding: "0", border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: "5px" }}
                      onKeyPress={(e) => (e.charCode === 13 ? pickProduct(obj) : null)}
                      onClick={() => {
                        posConfig.allowReturnExchange === "Y" && localStorage.getItem("salesReturn") === "Y"
                          ? pickProduct(obj)
                          : localStorage.getItem("salesReturn") === "N"
                            ? pickProduct(obj)
                            : null;
                      }}
                      style={cardDetailsWithoutImg}
                    >
                      <Row>
                        <Col span={24} style={{ height: "9.8vh", padding: "0.3vw" }}>
                          <Row style={{ paddingTop: "0.4vh", height: "3vh" }}>
                            <Col
                              span={14}
                              offset={1}
                              style={{
                                maxWidth: "65% !important",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                position: "relative",
                                objectFit: "cover",
                                height: "3vh",
                              }}
                            >
                              <label title={obj["name"]} style={data.posScreenStyles.productsCard.cardDetails.prodName} className="productName">
                                {obj["name"]}
                              </label>
                            </Col>
                            <Col span={8} style={{ textAlign: "right", height: "3vh" }}>
                              <label style={data.posScreenStyles.productsCard.cardDetails.productPrice}>
                                {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}&nbsp;
                                <span>
                                  {obj.sunitprice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                </span>
                              </label>
                            </Col>
                          </Row>
                          <Row style={{ height: "5vh", paddingTop: "1.5vh" }}>
                            <Col span={23} offset={1} style={{ lineHeight: "1" }}>
                              <span className="productDescription" title={obj["description"]}>
                                {obj["description"]?.substring(0, 25)}...
                              </span>
                            </Col>
                          </Row>
                          {obj.selected === "Y" ? (
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "rgba(0, 0, 0, 0.44)",
                                borderRadius: "7px",
                              }}
                            >
                              {/*  <img src={tick} style={{ alignSelf: "center", height: "3vh" }} />*/}
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              ))}
              <span tabIndex={0}></span>
            </Row>
          </div>
        </Scrollbars>
      ) : productsDisplay === "superMarket" ? (
        <Scrollbars autoHide className="productScrollKiosk">
          <div style={{ width: "100%", padding: "1.5% 0" }}>
            <Row gutter={[16, 16]} style={{ width: "100%" }}>
              {productsList.map((obj, i) => (
                <Col key={`${obj.mProductId}${obj.name}`} span={6}>
                  {posConfig.showImage === "Y" ? (
                    <div
                      ref={i === 0 ? productListCardRef : null}
                      id={`productCardItem-${i}`}
                      onBlur={() => focusFirstElement(i)}
                      tabIndex={0}
                      onKeyPress={(e) => (e.charCode === 13 ? pickProduct(obj) : null)}
                      style={superMarketCartDetails}
                    >
                      <Row>
                        <Col span={24} style={{ textAlign: "center", height: "11vh" }}>
                          <img
                            onError={setDefaultImage}
                            style={{
                              width: "100%",
                              height: "11vh",
                              objectFit: "contain",
                              borderRadius: "5px",
                              paddingTop:"1vh"
                            }}
                            src={obj.imageurl || DefaultProductImage}
                            alt=""
                          />
                        </Col>
                      </Row>
                      <Row style={{ height: "5vh" }}>
                        <Col
                          span={14}
                          offset={1}
                          style={{
                            maxWidth: "65% !important",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            position: "relative",
                            objectFit: "cover",
                            height: "2.5vh",
                          }}
                        >
                          <label style={data.posScreenStyles.productsCard.cardDetails.productDescr} className="productName">
                            {obj["description"]}
                          </label>
                        </Col>
                        <Col span={8} style={{ textAlign: "right", height: "2.5vh" }}>
                        </Col>

                        <Col
                          span={14}
                          offset={1}
                          style={{
                            maxWidth: "65% !important",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            position: "relative",
                            objectFit: "cover",
                          }}
                        >
                          <label style={data.posScreenStyles.productsCard.cardDetails.superMarketProd} className="productName">
                            {obj["name"]}
                          </label>
                        </Col>
                      </Row>
                      <Row style={{ height: "6vh",display:"flex",alignItems:"center",paddingBottom:"0.4vh"}}>
                        <Col span={14} offset={1} style={{ lineHeight: "1" }}>
                          <span className="productDescription-kiosk" title={obj["description"]}>
                            {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                              {obj.sunitprice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}/{obj["uomName"]}
                          </span>
                        </Col>
                        <Col span={9} style={{ lineHeight: "1" }} 
                        onClick={() => {
                          posConfig.allowReturnExchange === "N" && localStorage.getItem("salesReturn") === "Y"
                            ? pickProduct(obj)
                            : localStorage.getItem("salesReturn") === "N"
                              ? pickProduct(obj)
                              : null; 
                        }}>
                          <span style={{fontSize:"1.5vh",color:"#fff",backgroundColor:"#2F3856",padding:"0.85vh 1.7vw",borderRadius:"3px",cursor:"pointer"}}>ADD</span>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div
                      ref={i === 0 ? productListCardRef : null}
                      id={`productCardItem-${i}`}
                      onBlur={() => focusFirstElement(i)}
                      tabIndex={0}
                      bodyStyle={{ padding: "0", border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: "5px" }}
                      onKeyPress={(e) => (e.charCode === 13 ? pickProduct(obj) : null)}
                      onClick={() => {
                        posConfig.allowReturnExchange === "Y" && localStorage.getItem("salesReturn") === "Y"
                          ? pickProduct(obj)
                          : localStorage.getItem("salesReturn") === "N"
                            ? pickProduct(obj)
                            : null;
                      }}
                      style={cardDetailsWithoutImg}
                    >
                      <Row>
                        <Col span={24} style={{ height: "9.8vh", padding: "0.3vw" }}>
                          <Row style={{ paddingTop: "0.4vh", height: "3vh" }}>
                            <Col
                              span={14}
                              offset={1}
                              style={{
                                maxWidth: "65% !important",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                position: "relative",
                                objectFit: "cover",
                                height: "3vh",
                              }}
                            >
                              <label title={obj["name"]} style={data.posScreenStyles.productsCard.cardDetails.prodName} className="productName">
                                {obj["name"]}
                              </label>
                            </Col>
                            <Col span={8} style={{ textAlign: "right", height: "3vh" }}>
                              <label style={data.posScreenStyles.productsCard.cardDetails.productPrice}>
                                {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}&nbsp;
                                <span>
                                  {obj.sunitprice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                </span>
                              </label>
                            </Col>
                          </Row>
                          <Row style={{ height: "5vh", paddingTop: "1.5vh" }}>
                            <Col span={23} offset={1} style={{ lineHeight: "1" }}>
                              <span className="productDescription" title={obj["description"]}>
                                {obj["description"]?.substring(0, 25)}...
                              </span>
                            </Col>
                          </Row>
                          {obj.selected === "Y" ? (
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "rgba(0, 0, 0, 0.44)",
                                borderRadius: "7px",
                              }}
                            >
                              {/*  <img src={tick} style={{ alignSelf: "center", height: "3vh" }} />*/}
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              ))}
              <span tabIndex={0}></span>
            </Row>
          </div>
        </Scrollbars>
      ) : (
        <Scrollbars autoHide className="productScroll">
          <Row>
            <Row gutter={[15, 11]} style={{ width: "100%" }}>
              {productsList.map((obj, i) => (
                <Col key={`${obj.mProductId}${obj.name}`} span={isProductsFilter ? 12 : productsCount === 2 ? 12 : 6} xs={12} sm={12} md={8} lg={8} xl={6}>
                  {posConfig.showImage === "Y" ? (
                    <div
                      ref={i === 0 ? productListCardRef : null}
                      id={`productCardItem-${i}`}
                      onBlur={() => focusFirstElement(i)}
                      tabIndex={0}
                      bodyStyle={{ padding: "0", borderRadius: "10px" }}
                      onKeyPress={(e) => (e.charCode === 13 ? pickProduct(obj) : null)}
                      onClick={() => {pickProduct(obj)}}
                      style={cardDetails}
                    >
                      <Row>
                        <Col span={24} style={{ textAlign: "center", height: "11vh" }}>
                          <img
                            onError={setDefaultImage}
                            style={{
                              width: "100%",
                              height: "10.8vh",
                              objectFit: "contain",
                              borderRadius: "9px",
                              padding: "6px",
                              background: obj.selected === "Y" ? "rgba(0, 0, 0, 0.44)" : "",
                              // height: props.productsList.length === 2 ? "80px" : "130px",
                              // width: props.productsList.length === 2 ? "80px" : "130px",
                              // marginTop: "15%",
                            }}
                            src={obj.imageurl || DefaultProductImage}
                            alt=""
                          />
                          {obj.selected === "Y" ? (
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "rgba(0, 0, 0, 0.44)",
                                borderRadius: "9px",
                              }}
                            >
                              <img src={tick} style={{ alignSelf: "center", height: "3vh" }} />
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: "0.4vh", height: "3vh" }}>
                        <Col
                          span={24}
                          offset={1}
                          style={{
                            maxWidth: "90%",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            position: "relative",
                            objectFit: "cover",
                            height: "3vh",
                          }}
                        >
                          <label title={obj["name"]} style={data.posScreenStyles.productsCard.cardDetails.prodName} className="productName">
                            {obj["name"]}
                          </label>
                        </Col>
                        <Col span={24} style={{ textAlign: "left", height: "3vh" }}>
                          <label style={data.posScreenStyles.productsCard.cardDetails.productPrice}>
                            {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}&nbsp;
                            <span>
                              {obj.sunitprice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                            </span>
                          </label>
                        </Col>
                      </Row>
                      <Row style={{ height: "5vh", paddingTop: "0.2vh" }}>
                        <Col span={23} offset={1} style={{ lineHeight: "1" }}>
                          {/* <span className="productDescription" title={obj["description"]}>
                            {obj["description"]?.substring(0, 25)}...
                          </span> */}
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div
                      ref={i === 0 ? productListCardRef : null}
                      id={`productCardItem-${i}`}
                      onBlur={() => focusFirstElement(i)}
                      tabIndex={0}
                      bodyStyle={{ padding: "0", border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: "5px" }}
                      onKeyPress={(e) => (e.charCode === 13 ? pickProduct(obj) : null)}
                      onClick={() => {
                        posConfig.allowReturnExchange === "Y" && localStorage.getItem("salesReturn") === "Y"
                          ? pickProduct(obj)
                          : localStorage.getItem("salesReturn") === "N"
                            ? pickProduct(obj)
                            : null;
                      }}
                      style={cardDetailsWithoutImg}
                    >
                      <Row>
                        <Col span={24} style={{ height: "9.8vh", padding: "0.3vw" }}>
                          <Row style={{ paddingTop: "0.4vh", height: "3vh" }}>
                            <Col
                              span={14}
                              offset={1}
                              style={{
                                maxWidth: "65% !important",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                position: "relative",
                                objectFit: "cover",
                                height: "3vh",
                              }}
                            >
                              <label title={obj["name"]} style={data.posScreenStyles.productsCard.cardDetails.prodName} className="productName">
                                {obj["name"]}
                              </label>
                            </Col>
                            <Col span={8} style={{ textAlign: "right", height: "3vh" }}>
                              <label style={data.posScreenStyles.productsCard.cardDetails.productPrice}>
                                {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}&nbsp;
                                <span>
                                  {obj.sunitprice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                </span>
                              </label>
                            </Col>
                          </Row>
                          <Row style={{ height: "5vh", paddingTop: "1.5vh" }}>
                            <Col span={23} offset={1} style={{ lineHeight: "1" }}>
                              <span className="productDescription" title={obj["description"]}>
                                {obj["description"]?.substring(0, 25)}...
                              </span>
                            </Col>
                          </Row>
                          {obj.selected === "Y" ? (
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "rgba(0, 0, 0, 0.44)",
                                borderRadius: "7px",
                              }}
                            >
                              <img src={tick} style={{ alignSelf: "center", height: "3vh" }} />
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              ))}
              <span tabIndex={0}></span>
            </Row>
          </Row>
        </Scrollbars>
      )}

      {/* BATCH SELECTION MODAL START*/}
      <Modal visible={displayBatchSelection} keyboard={false} closable={false} footer={null} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
        <Row>
          <Col span={12}>
            <p style={data.posScreenStyles.offerSelectionModal.title}>Product Batch</p>
          </Col>
          <Col span={12}>
            <p style={data.posScreenStyles.offerSelectionModal.closeIconP}>
              <CloseOutlined onClick={() => setDisplayBatchSelection(false)} style={data.posScreenStyles.offerSelectionModal.closeIcon} />
            </p>
          </Col>
        </Row>
        <Row>
          <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
            <Scrollbars autoHide className="orderHistoryScroll">
              {batchSetAvailable.map((item) => (
                <Card
                  style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard}
                  bodyStyle={{ padding: 10 }}
                  onClick={() => selectProductToCart(item)}
                  onKeyPress={(e) => (e.charCode === 13 ? selectProductToCart(item) : null)}
                  className="focusDashboardCard"
                  tabIndex={0}
                >
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderDate}>Batch</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>Mrp</p>
                    </Col>
                    {/* <Col span={8}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>Price</p>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.batchno}</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>
                        {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} {parseFloat(item.mrpPrice).toFixed(2)}{" "}
                        {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                      </p>
                    </Col>
                    {/* <Col span={8}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>{currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} {parseFloat(item.salePrice).toFixed(2)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</p>
                    </Col> */}
                  </Row>
                </Card>
              ))}
              {/* <span tabIndex={0}></span> */}
            </Scrollbars>
          </Card>
        </Row>
      </Modal>
      {/* BATCH SELECTION MODAL END*/}
    </React.Fragment>
  );
};

export default ProductPanel;
