import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Card, Input, Button,message } from "antd";
import { useHistory } from "react-router-dom";
// import { serverUrl } from "../../constants/config";
import { Link } from "react-router-dom";
import { clock } from "../../utility/clock";
import { timeStamp } from "../../utility/timestamp";
import { v4 as uuidv4 } from "uuid";
import { MinusOutlined, PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import MenuIcon from "../../assets/images/menu.png";
import POSLogo from "../../assets/images/icon.png";
import Axios from "axios";
import db from "../../database";
import data from "../../constants/opentillClosetill.json";
import "../style.css";
import _ from "lodash";
import moment from "moment";
import { getOAuthHeaders } from "../../constants/oAuthValidation";

const OpenTill = () => {
  const history = useHistory();
  const serverUrl = process.env.REACT_APP_serverUrl;
  const dynamicHeight = 0.5625;
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const userData = JSON.parse(localStorage.getItem("userData"))
  const currenciesList = tillData?.tillAccess?.csBunit?.currencies;
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  const paymentMethodList = tillData.tillAccess.csBunit.paymentMethodList;
  const [noteValue, setNoteValue] = useState("");
  const [nextFlag, setNextFlag] = useState(false);


  const [currencyType, setCurrencyType] = useState({
    currSymbolLeft: "₹",
    currSymbolRight: "",
    stdPrecision: 2,
  });

  useEffect(() => {
    const tillSession = localStorage.getItem("tillSession");
    if (tillSession) {
      if (JSON.parse(tillSession).tillStatus === "open") history.push(tillLayout === 2 && tillaccess?.dineIn === "Y" ? "/table-management" : "/pos");
    } else {
      db.tillRegistrations.toArray().then((reg) => {
        if (reg.length > 0) {
          const curList = reg[0].tillAccess[0].csBunit.csCurrency;
          const csCurrencyId = curList.csCurrencyId;
          localStorage.setItem("csCurrencyId", csCurrencyId);
          setCurrencyType({
            currSymbolLeft: curList.symbolrightside ? "" : curList.currSymbol,
            currSymbolRight: curList.symbolrightside ? curList.currSymbol : "",
            stdPrecision: curList.stdPrecision,
          });
        }
      });
    }
  }, []);

  const [denominations, setDenominations] = useState([]);

  useEffect(() => {
    const modifiedCurrenciesList = currenciesList[0].denominations?.map((denomItem, denomIndex) => ({
      ...denomItem,
      count: 0,
      amount: 0
    }))
    const sortedDenominations = modifiedCurrenciesList?.sort((a, b) => a.value - b.value);

    setDenominations([...sortedDenominations]);
    // setDenominations([...modifiedCurrenciesList]);
  }, [])

  // const [denominations, setDenominations] = useState([
  //   {
  //     denomination: 1,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 2,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 5,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 10,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 20,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 50,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 100,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 200,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 500,
  //     count: 0,
  //     amount: 0,
  //   },
  //   {
  //     denomination: 2000,
  //     count: 0,
  //     amount: 0,
  //   },
  // ]);

  const currentDate = new Date().toLocaleDateString();
  const [displayClock, setDisplayClock] = useState("");
  useEffect(() => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const [totalAmount, setTotalAmount] = useState(0);

  const onFieldInput = (e) => {
    const { name, value } = e.target;
    const denominationName = parseInt(name);
    const denominationValue = parseInt(value);
    const denominationAmount = denominationName * denominationValue;
    const di = denominations.findIndex((d) => d.value === denominationName);
    denominations[di].count = isNaN(denominationValue) ? "" : denominationValue;
    denominations[di].amount = isNaN(denominationAmount) ? 0 : denominationAmount;
    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    setTotalAmount(total);
    setDenominations([...denominations]);
  };

  const onFieldOut = (e) => {
    if (e.target.value === "") {
      const di = denominations.findIndex((d) => d.value === parseInt(e.target.name));
      denominations[di].count = 0;
      setDenominations([...denominations]);
    }
  };

  const onFieldButton = (field, action) => {
    if (action === "plus") denominations[field].count += 1;
    if (action === "minus" && denominations[field].count > 0) denominations[field].count -= 1;
    const denominationAmount = denominations[field].value * denominations[field].count;
    const denominationAmountValue = isNaN(denominationAmount) ? 0 : denominationAmount;
    denominations[field].amount = denominationAmountValue;
    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    setTotalAmount(total);
    setDenominations([...denominations]);
  };

  const confirmBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);

  const excuteOpenTill = () => {
    const authHeaders = getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      confirmBtnRef.current.disabled = true;
      const tillSessionId = uuidv4().replace(/-/g, "").toUpperCase();
      const openTillData = {
        tillSessionId: tillSessionId,
        tillStatus: "open",
        openingTime: timeStamp(),
        closingTime: null,
        openingCashDenominations: denominations,
        closingCashDenominations: null,
        openingCash: totalAmount,
        closingCash: null,
        totalOpeningAmount: totalAmount,
        totalClosingExpectedAmount: null,
        totalClosingTransactions: null,
        totalClosingAmount: null,
      };
      const ordersData = {
        tillSessionId: tillSessionId,
        tillStatus: "open",
        date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        netTotal:0,
        grossTotal:0,
        totalTax:0,
        items:[],
        netReturnTotal: 0,
        grossReturnTotal: 0,
        totaReturnlTax: 0,
        salesTransactions: 0,
        returnsTransactions: 0,
      };

      const paymentsData = {
        tillSessionId: tillSessionId,
        tillStatus: "open",
        date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        payments:paymentMethodList,
      };

      db.tillEvents.add(openTillData);

      db.ordersData.add(ordersData);
      db.paymentsData.add(paymentsData);
      localStorage.setItem("tillSession", JSON.stringify(openTillData));
      if (db.logConfiguration.toArray().then((fetched) => {
        fetched.map((item) => {
          item.dayOpening.filter((item) => item.log === "Y").length > 0;
        });
      })) {
        const timeMark = timeStamp();
        const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
        const dayOpeningArray = [];
        dayOpeningArray.push(`{
          type: "DON",
          action: "LOG",
          description: "${timeMark}",
          date: "${currentDate}",
          time: "${timeMark}",
          orderNo: "",
          remarks: "",
        }`)
        Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `mutation {
            upsertPOSLog(order: {
                tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
                userId: "${tillData.tillAccess.csUserId}" 
                bUnitId: "${tillData.tillAccess.csBunit.csBunitId}", 
                lines: [${dayOpeningArray}]
              }) {
              status   
              message
            }
          }`,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${authHeaders.access_token}`,
          },
        })
      }
      confirmBtnRef.current.disabled = false;
      const cashEventDetails = openTillData.openingCashDenominations.map(denomination => ({
        cwrCashEventdetailsID: uuidv4().replace(/-/g, "").toUpperCase(), // Generate UUID for each detail
        count: denomination.count,
        amount: denomination.amount,
        denomination: denomination.value
    }));
    let index = tillData.tillAccess.csBunit.paymentMethodList.findIndex((payment) => payment.name.toLowerCase() === "cash")
    
      const paramsInput = {
        query: `mutation{
          upsertTill(tillInfo:{
            cwrTillID:"${tillData.tillAccess.cwrTill.cwrTillID}"
            csbunitID:"${tillData.tillAccess.csBunit.csBunitId}"
            csclientID:"${tillData.tillAccess.csClientId}"
            created:"${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
            createdby:"${tillData.tillAccess.csUserId}"
            updated:"${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
            updatedby:"${tillData.tillAccess.csUserId}"
            tillCash:[{
              cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
              date: "${moment(new Date()).format("YYYY-MM-DD")}"
              finPaymentmethodId: "${tillData.tillAccess.csBunit.paymentMethodList[index].finPaymentmethodId}"
              finFinancialAccount2Id: null
              opening: ${parseFloat(totalAmount).toFixed(currencyType.stdPrecision)}
              opentime : "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
              closetime: null
              notes: "${noteValue}"
              sales: 0
              netsales: 0
              cashin: 0
              cashout: 0
              retainAmt: 0
              closing: 0
              returns: 0
              iscash: 1
              isclose: 0
              pettyCashIn: 0,
              pettyCashOut: 0,
              cashInOut: [],
              cashEvents:[
                {
                  cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                  amount: ${openTillData.openingCash}
                  expected: 0
                  diff: 0
                  transactionCount: 0
                  type: "Open"
                  description: ""
                  cashEventDetails: [${cashEventDetails.map(detail => `{ cwrCashEventdetailsID: "${detail.cwrCashEventdetailsID}", count: ${detail.count}, amount: ${detail.amount}, denomination: ${detail.denomination} }`).join(', ')}] 
                }
              ]
            }]
          })
          {    
            status
            message
            cwrTillID
                  tillCash{
              
              cwrTillCashId
              date
              finPaymentmethodId
              opening
              sales
              notes
              netsales
              cashin
              cashout
              retainAmt
              closing
              returns
              iscash
              isclose
              storeDailyOpsTillid
              cashEvents{
                cwrCashEventsID
                amount
                expected
                diff
                transactionCount
                type
                description
                cashEventDetails{
                  cwrCashEventdetailsID
                  count
                  amount
                  denomination
                }
              }
            }
          }
        }`,
      };

      Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${authHeaders.access_token}`,
        },
      }).then((response) => {
        if(response.data.data.upsertTill.status === "200"){
          localStorage.setItem("tillCash",JSON.stringify(response.data.data.upsertTill.tillCash));
          localStorage.setItem("storeDailyOpsTillid",response.data.data.upsertTill.tillCash[0].storeDailyOpsTillid);
          history.push(tillLayout === 2 && tillaccess?.dineIn === "Y" ? "/table-management" : "/pos");
        }else {
          message.error(response.data.data.upsertTill.message)
        }
      })

    };
  };

  const focusFirstElement = () => {
    setTimeout(() => {
      // cancelBtnRef.current.focus();
    }, 100);
  };

  const resetFocus = (index) => {
    if (index + 1 === denominations.length) {
      focusFirstElement();
    }
  };

  const handleHome = () => {
    history.push("/dashboard");
  }

  let reconstructedObject;
  // const rawData = tillData?.tillAccess?.tillAccessMeta[0]?.value;
  const rawtillAccessMeta = tillData?.tillAccess?.tillAccessMeta;
  if(rawtillAccessMeta){
     const rawDataArray = tillData?.tillAccess?.tillAccessMeta;

// Find the object with the key "Open Till"
const openTillObject = rawDataArray.find(item => item.key === 'Open Till');

// Access the value property of the found object
const openTillValue = openTillObject?.value;
    // const rawData = tillData?.tillAccess?.tillAccessMeta?.[0]?.value;

    if (openTillValue !== undefined) {
      // The rest of your code that depends on openTillValue
        // Clean up the string (remove extra characters)
    const cleanedData = openTillValue.replace(/[{}"]/g, '');
    
    // Split the string into key-value pairs
    const keyValuePairs = cleanedData.split(',');
    
    // Create an object from key-value pairs
     reconstructedObject = {};
    keyValuePairs.forEach(pair => {
      // Check if the pair contains a colon
      if (pair.includes(':')) {
        const [key, value] = pair.split(':');
        
        // Check if both key and value are not undefined before trimming
        if (key !== undefined && value !== undefined) {
          reconstructedObject[key.trim()] = value.trim();
        }
      }
    });
    } else {
      // Handle the case where openTillValue is undefined
      console.error('openTillValue is undefined');
    }
  }
  
  
  return (
    <div style={data.openTill.mainContainer}>
      <Row style={{ height: "6vh", padding: "1% 0 0 1.5%" }}>
        <Col span={19} style={{ display: "flex", alignItems: "center" }}>
          <img src={POSLogo} style={data.openTill.header.menuIcon} alt="" />
          <label style={data.openTill.header.timeFmt}>Open Till</label>
        </Col>
        <Col span={2}>
        </Col>
        <Col span={8}>
        </Col>
        <Col span={4} style={data.openTill.header.right}>
        </Col>
      </Row>
      <div style={data.openTill.mainCardContainer}>
        <div style={{ padding: "1vw", display: "flex", paddingBottom: "0" }}>
          <div style={{ borderRadius: "3px", width: "68.5%" }}>
            <div key="z" style={data.openTill.mainCardFirst}>
              <Row style={{ padding: "1% 0%", borderBottom: "1px solid rgba(0, 0, 0, 0.09)" }}>
                <Col span={7} style={data.openTill.colAlign}>
                  <strong>Denomination</strong>
                </Col>
                <Col span={10} style={data.openTill.colAlign}>
                  <strong>Count</strong>
                </Col>
                <Col span={7} style={data.openTill.colAlign}>
                  <strong>Total Amount</strong>
                </Col>
              </Row>
              <Row >
                <Scrollbars autoHide className="tillScroll">
                  {denominations.map((denom, index) => (
                    <Row>
                      <Col span={7} style={data.openTill.colAlign}>
                        <Input tabIndex={-1} readOnly={true} style={data.openTill.currency} value={denom.value} />
                      </Col>
                      <Col span={10} style={data.openTill.colAlign}>
                        <div style={data.openTill.calInputDiv}>
                          {/* <span style={data.openTill.decInpt}> */}
                          <MinusOutlined style={data.openTill.incInpt} type="minus" onClick={() => onFieldButton(index, "minus")} />
                          {/* </span> */}
                          <Form.Item onBlur={() => resetFocus(index)} style={data.openTill.countFormitem}>
                            <Input tabIndex={0} name={`${denom.value}`} value={denom.count} style={data.openTill.calInput} onChange={onFieldInput} onBlur={onFieldOut} />
                          </Form.Item>
                          <PlusOutlined style={data.openTill.incInpt} type="plus" onClick={() => onFieldButton(index, "plus")} />
                        </div>
                      </Col>
                      <Col span={7} style={data.openTill.colAlign}>
                        <Input
                          tabIndex={-1}
                          style={data.openTill.totalInput}
                          readOnly={true}
                          value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${denom.amount} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                        />
                      </Col>
                    </Row>
                  ))}
                  <span tabIndex={0}></span>
                </Scrollbars>
              </Row>
            </div>
          </div>
          <div style={{ width: "32.5%", paddingLeft: "2.3%" }}>
            <div style={data.openTill.opentillRightCard}>
              <p style={data.openTill.emptyP}>Opened On</p>
              <div style={{ marginBottom: "3%", marginTop: "-6px" }}>
                <span style={data.openTill.currentDate}>{currentDate}</span>
                <span style={data.openTill.displayClock}>{displayClock}</span>
              </div>
              <p style={{
                "marginBottom": "3%",
                "fontSize": "2.3vh",
                "fontFamily": "Inter",
                "fontWeight": "600",
                "letterSpacing": "0px",
                "color": "#000000",
              }}>Transactions</p>
              <Form>
                <Form.Item style={{ marginBottom: "2vh" }}>
                  <p style={data.openTill.transactionsAmt}>Opening Amount</p>
                  <Input
                    placeholder="₹0"
                    tabIndex={-1}
                    value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(totalAmount).toFixed(currencyType.stdPrecision)} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                    readOnly={true}
                    className="transactionAmtInput"
                    style={{ marginBottom: "2vh", height: "6vh" }}
                  />

                  <p style={data.openTill.transactionsAmt}>Note</p>
                  <Input
                    tabIndex={-1}
                    placeholder="Enter note"
                    onChange={(e) => setNoteValue(e.target.value)}
                    // readOnly={true}
                    className="transactionAmtInput"
                    style={{ height: "6vh" }}
                  />
                </Form.Item>
              </Form>
              {/* <p style={{
                "marginBottom": "0.5%",
                "fontSize": "2.3vh",
                "fontFamily": "Inter",
                "fontWeight": "600",
                "letterSpacing": "0px",
                "marginTop": "2.3vh",
                "color": "#000000",
              }}>Last Closure Details</p> */}
              <Row style={{ height: "auto" }}>
                <Col span={12}><p style={data.openTill.transactionsAmt}>Outlet</p></Col>
                <Col span={12}><p style={data.openTill.transactionsAmtRight}>{userData.bunit_iden}</p></Col>
                <Col span={12}><p style={data.openTill.transactionsAmt}>Register</p></Col>
                <Col span={12}><p style={data.openTill.transactionsAmtRight}>{tillData.tillAccess.cwrTill.till}</p></Col>
                <Col style={{display:reconstructedObject?reconstructedObject.OpeningAmount==="Y"?"block":"none":"block"}} span={12}><p style={data.openTill.transactionsAmt}>Opening Amount</p></Col>
                <Col style={{display:reconstructedObject?reconstructedObject.OpeningAmount==="Y"?"block":"none":"block"}} span={12}><p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p></Col>
                <Col style={{display:reconstructedObject?reconstructedObject.CashSaleAmount==="Y"?"block":"none":"block"}} span={12}><p style={data.openTill.transactionsAmt}>Cash Sale Amount</p></Col>
                <Col style={{display:reconstructedObject?reconstructedObject.CashSaleAmount==="Y"?"block":"none":"block"}} span={12}><p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p></Col>
                <Col style={{display:reconstructedObject?reconstructedObject.CashIn==="Y"?"block":"none":"block"}} span={12}><p style={data.openTill.transactionsAmt}>Cash in</p></Col>
                <Col style={{display:reconstructedObject?reconstructedObject.CashIn==="Y"?"block":"none":"block"}} span={12}><p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p></Col>
                <Col style={{display:reconstructedObject?reconstructedObject.CashOut==="Y"?"block":"none":"block"}} span={12}><p style={data.openTill.transactionsAmt}>Cash out</p></Col>
                <Col style={{display:reconstructedObject?reconstructedObject.CashOut==="Y"?"block":"none":"block"}} span={12}><p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p></Col>
                <Col style={{display:reconstructedObject?reconstructedObject.PettyCashIn==="Y"?"block":"none":"block"}} span={12}><p style={data.openTill.transactionsAmt}>Petty Cash in</p></Col>
                <Col style={{display:reconstructedObject?reconstructedObject.PettyCashIn==="Y"?"block":"none":"block"}} span={12}><p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p></Col>
                <Col style={{display:reconstructedObject?reconstructedObject.pettyCashOut==="Y"?"block":"none":"block"}} span={12}><p style={data.openTill.transactionsAmt}>Petty Cash out</p></Col>
                <Col style={{display:reconstructedObject?reconstructedObject.pettyCashOut==="Y"?"block":"none":"block"}} span={12}><p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p></Col>
                <Col style={{display:reconstructedObject?reconstructedObject.ExpectedCash==="Y"?"block":"none":"block"}} span={12}><p style={data.openTill.transactionsAmt}>Expected Cash </p></Col>
                <Col style={{display:reconstructedObject?reconstructedObject.ExpectedCash==="Y"?"block":"none":"block"}} span={12}><p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p></Col>
                <Col span={12}><p style={data.openTill.transactionsAmt}>Actual Cash</p></Col>
                <Col span={12}><p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p></Col>
              </Row>
              <Row style={{ paddingTop: "7%" }}>
                <Button tabIndex={1} className="focusButtonsTill" ref={confirmBtnRef} style={data.openTill.btnConfirm1} /*onClick={()=>setNextFlag(true)}*/ onClick={excuteOpenTill}>
                  Next
                </Button>
              </Row>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenTill;
