import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, message, Input, Button, Checkbox, Divider ,} from "antd";
import { useHistory } from "react-router-dom";
// import { serverUrl } from "../../constants/config";
import { Link } from "react-router-dom";
import { clock } from "../../utility/clock";
import { timeStamp } from "../../utility/timestamp";
import { tillClosingPrint } from "../../lib/printer/tillCloseReceipt";
import { Scrollbars } from "react-custom-scrollbars";
import MenuIcon from "../../assets/images/icon.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import CancelArrow from "../../assets/images/cancelArrow.svg";
import Axios from "axios";
import db from "../../database";
import data from "../../constants/opentillClosetill.json";
import retailStyles from "../../constants/retailPos.json"
import "../style.css";
import { v4 as uuidv4 } from "uuid";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import moment from "moment";
import TillCloseHCPrint from "../../lib/printer/tillCloseHCPrinter";

const CashToKeep = () => {
  const serverUrl = process.env.REACT_APP_serverUrl;
  const SSOURL = process.env.REACT_APP_SSOURL;
  const redirectUrl = process.env.REACT_APP_redirectURL;
  const dynamicHeight = 0.5625;
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillSession = JSON.parse(localStorage.getItem("tillSession"));
  const domainURL = process.env.REACT_APP_domain;
  const Instance = process.env.REACT_APP_INSTANCE_TYPE;
  const [allExpectedAmount, setAllExpectedAmount] = useState(0);
  const [allTransactionCount, setAllTransactionCount] = useState(0);
  const [paymentsList, setPaymentsList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));

  const paymentMethodList = tillData.tillAccess.csBunit.paymentMethodList;
  const sessionId = tillSession.tillSessionId;
  const [totalOpeningAmount, setTotalOpeningAmount] = useState(0);
  const [salesAmount, setSalesAmount] = useState(0);
  const [cashSaleAmount, setCashSaleAmount] = useState(0);
  const [noteValue, setNoteValue] = useState("");
  const [cashToKeep,setCashToKeep] = useState(0);
  const [cashValues, setCashValues] = useState({ cashIn: 0, cashOut: 0, pettCashIn: 0, pettCashOut: 0 });
  const authHeaders = getOAuthHeaders();
  const ObFlag = tillData.tillAccess.cwrTill.tillCloseTemplate.obController === "Y" ? true : false;

  useEffect(async () => {
    let obj = cashValues;
    let payments = []
    await db.tillEvents
      .where("tillSessionId")
      .equals(sessionId)
      .toArray()
      .then((tillEvent) => {
        if (tillEvent.length > 0 && tillEvent[0].cashInOutData) {
          setCashValues(tillEvent[0].cashInOutData);
          obj = tillEvent[0].cashInOutData;
        }
        if(tillEvent.length > 0){
          tillEvent[0].allPaymentsData.map((payment) => {
            if(payment.name.toLowerCase() !== "cash"){
              payment.amount = payment.expectedAmount;
              payment.actualAmount = payment.expectedAmount;
              payment.difference = 0;
            }
          })
          payments = tillEvent[0].allPaymentsData;
        }
      });

    if (posConfig.showDenominations === "N") {
      // const newPaymentsList = [...payments];
      db.orders
        .where("tillSessionId")
        .equals(sessionId)
        .toArray()
        .then((orders) => {
          const cashIndex = payments.findIndex((p) => p.name.toLowerCase() === "cash");
          const openAmount = JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount;
          const cashAmount = parseFloat(payments[cashIndex].expectedAmount);
          setTotalOpeningAmount(openAmount);
          setTotalOrdersCount(orders.length);
          setCashSaleAmount(cashAmount);
          const updatedTillSession = tillSession;
          const updatedAllPaymentsData = payments;
          const aPi = updatedAllPaymentsData?.findIndex((apd) => apd.name.toLowerCase() === "cash");
          updatedAllPaymentsData[aPi].amount = totalAmount;
          updatedAllPaymentsData[aPi].expectedAmount = parseFloat(updatedAllPaymentsData[aPi].expectedAmount.toFixed(2));
          updatedAllPaymentsData[aPi].cashSaleAmount = cashSaleAmount;
          updatedAllPaymentsData[aPi].difference = parseFloat(updatedAllPaymentsData[aPi].amount) - parseFloat(updatedAllPaymentsData[aPi].expectedAmount);
          updatedAllPaymentsData[aPi].difference = parseFloat(updatedAllPaymentsData[aPi].difference.toFixed(2));
          updatedTillSession.closingTime = timeStamp();
          updatedTillSession.closingCash = totalAmount;
          updatedTillSession.closingCashDenominations = [];
          updatedTillSession.allPaymentsData = updatedAllPaymentsData;
          updatedTillSession.totalOrdersCount = orders.length;
          db.tillEvents.where("tillSessionId").equals(updatedTillSession.tillSessionId).modify(updatedTillSession);
          localStorage.setItem("tillSession", JSON.stringify(updatedTillSession));
          setAllExpectedAmount(updatedAllPaymentsData[aPi].expectedAmount);
          setAllTransactionCount(updatedAllPaymentsData[aPi].transactionCount);
          setTotalAmount(updatedAllPaymentsData[aPi].amount);
          setTotalOrdersCount(orders.length);
          setPaymentsList([...payments]);
        });
    } else if (posConfig.showDenominations === "Y") {
      const tillSessionPayments = tillSession.allPaymentsData;
      let totalAmountAllPayments = 0;
      let transactionCount = 0;
      let total = 0;
      let salesAmount = 0;
      tillSessionPayments?.map((pay) => {
        totalAmountAllPayments += pay.expectedAmount;
        transactionCount += pay.transactionCount;
        if (pay.name.toLowerCase() === "cash") {
          pay.expectedAmount = pay.expectedAmount - pay.paymentReturn;
          salesAmount += pay?.cashSaleAmount - pay?.paymentReturn;
        } else {
          salesAmount += pay?.expectedAmount - pay?.paymentReturn;
        }
        total += parseFloat(pay.expectedAmount);
        return null;
      });
      
      const openAmount = JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount;

      payments.map((ele) => {
        if (ele.name.toLowerCase() === "cash") {
          ele.expectedAmount = parseFloat(parseFloat(ele.expectedAmount - ele.paymentReturn).toFixed(2));
          setSalesAmount(ele.expectedAmount);
        }
      });
      totalAmountAllPayments = salesAmount + obj.cashIn - obj.cashOut + obj.pettCashIn - obj.pettCashOut + openAmount;
      setAllExpectedAmount(totalAmountAllPayments);
      setAllTransactionCount(transactionCount);
      setTotalAmount(total);
      setTotalOrdersCount(tillSession.totalOrdersCount);
      setPaymentsList([...payments]);
    }
  }, []);


  const history = useHistory();
  const currentDate = new Date().toLocaleDateString();
  const [displayClock, setDisplayClock] = useState("");
  const [checkedValue, setCheckedValue] = useState("Nothing");

  useEffect(async () => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const onChange = (e) => {
    if(e.target.value === "0"){
        setCashToKeep("");
    }
    setCheckedValue(e.target.value);
  };

  const excuteCloseTillAll = async () => {
    const updatedPaymentsList = paymentsList;
    let closingSession = tillSession;
    await db.tillEvents
      .where("tillStatus")
      .equalsIgnoreCase("open")
      .toArray()
      .then((tillEvent) => {
        if (tillEvent.length > 0) {
          closingSession = tillEvent[0];
        }
      });
    // closingSession.tillStatus = "closed";
    closingSession.totalClosingExpectedAmount = allExpectedAmount;
    closingSession.totalClosingTransactions = allTransactionCount;
    closingSession.totalClosingAmount = totalAmount;
    closingSession.allPaymentsData = updatedPaymentsList;
    closingSession.isSynced = 0;

    if (authHeaders && authHeaders.access_token) {
      if (
        db.logConfiguration.toArray().then((fetched) => {
          fetched.map((item) => {
            item.dayClosing.filter((item) => item.log === "Y").length > 0;
          });
        })
      ) {
        const timeMark = timeStamp();
        const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
        const dayClosingArray = [];
        dayClosingArray.push(`{
          type: "DCL",
          action: "LOG",
          description: "${timeMark}",
          date: "${currentDate}",
          time: "${timeMark}",
          orderNo: "",
          remarks: "",
        }`);
        await Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `mutation {
            upsertPOSLog(order: {
                tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
                userId: "${tillData.tillAccess.csUserId}" 
                bUnitId: "${tillData.tillAccess.csBunit.csBunitId}", 
                lines: [${dayClosingArray}]
              }) {
              status   
              message
            }
          }`,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${authHeaders.access_token}`,
          },
        }).then((res) => {
          if (res.data.data.upsertPOSLog.status === "200") {
            db.tillEvents
              .where("tillSessionId")
              .equals(closingSession.tillSessionId)
              .modify(closingSession)
              .then(async() => {
                if (ObFlag) {
                await  TillCloseHCPrint(closingSession, cashValues, paymentsList);
                } else {
                  tillClosingPrint(closingSession);
                }
              });

            const nWStatus = navigator.onLine ? "online" : "offline";
            if (nWStatus === "online") {
              db.tillEvents
                .where("isSynced")
                .equals(0)
                .toArray()
                .then(async (events) => {
                  if (events.length > 0) {
                    for (let i = 0; i < events.length; i += 1) {
                      const event = events[i];
                      const tillCash = [];
                      let returnCash;
                      for (let j = 0; j < event.allPaymentsData.length; j += 1) {
                        const paymentObj = event.allPaymentsData[j];
                        if (paymentObj.name.toLowerCase() === "cash") {
                          const openedCashEvents = [];
                          const closedCashEvents = [];
                          returnCash = paymentObj.paymentReturn;
                          for (let x = 0; x < event.openingCashDenominations.length; x += 1) {
                            openedCashEvents.push(`{
                              cwrCashEventdetailsID:"${uuidv4().replace(/-/g, "").toUpperCase()}"
                              count:${event.openingCashDenominations[x].count}
                              amount:${event.openingCashDenominations[x].amount}
                              denomination:${event.openingCashDenominations[x].value}
                            }`);
                          }
                          for (let z = 0; z < event.closingCashDenominations?.length; z += 1) {
                            closedCashEvents.push(`{
                              cwrCashEventdetailsID:"${uuidv4().replace(/-/g, "").toUpperCase()}"
                              count:${event.closingCashDenominations[z].count}
                              amount:${event.closingCashDenominations[z].amount}
                              denomination:${event.closingCashDenominations[z].value}
                            }`);
                          }
                          let cashInOut = [];
                          let cashIn = 0;
                          let cashOut = 0;
                          let pattyCashIn = 0;
                          let pettyCashOut = 0;

                          await db.cashInCashOut.toArray((data) => {
                            data.map((item) => {
                              if (item.type === "cashIn") {
                                cashIn += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "CI",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`);
                              } else if (item.type === "cashOut") {
                                cashOut += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "CO",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`);
                              }
                              if (item.type === "pettyCashIn") {
                                pattyCashIn += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "PCI",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`);
                              }
                              if (item.type === "pettyCashOut") {
                                pettyCashOut += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "PCO",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`);
                              }
                            });
                          });
                        let tillCashData = JSON.parse(localStorage.getItem("tillCash")).filter(ele => ele.finPaymentmethodId === paymentObj.finPaymentmethodId);
                          tillCash.push(`{
                            cwrTillCashId: "${tillCashData.length >0 ? tillCashData.cwrTillCashId : uuidv4().replace(/-/g, "").toUpperCase()}"
                            date: "${moment(new Date()).format("YYYY-MM-DD")}"
                            finPaymentmethodId: "${paymentObj.finPaymentmethodId}"
                            finFinancialAccount2Id: ${paymentObj.finDayCloseAccountId && parseFloat(event.closingCash) !== 0 ? `"${paymentObj.finDayCloseAccountId}"` : null}
                            opening: ${event.openingCash}
                            opentime : "${moment(event.openingTime).format("YYYY-MM-DD HH:mm:ss")}"
                            closetime: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                            sales: ${parseFloat(paymentObj.cashSaleAmount.toFixed(2))}
                            netsales: ${parseFloat(paymentObj.cashSaleAmount.toFixed(2))}
                            cashin: ${cashIn}
                            cashout: ${cashOut}
                            retainAmt: ${checkedValue === "0" ? salesAmount : cashToKeep}
                            closing: ${event.closingCash}
                            returns: ${paymentObj.paymentReturn}
                            iscash: 1
                            isclose: 1
                            notes: "${noteValue}"
                            pettyCashIn: ${pattyCashIn},
                            pettyCashOut: ${pettyCashOut},
                            cashInOut: [${cashInOut}],
                            cashEvents: [
                              {
                                cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                                amount: ${event.openingCash}
                                expected: 0
                                diff: 0
                                transactionCount: 0
                                type: "Open"
                                description: ""
                                cashEventDetails: [${openedCashEvents}]
                              },
                              {
                                cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                                amount: ${event.closingCash}
                                expected: ${paymentObj.expectedAmount}
                                diff: ${paymentObj.difference}
                                transactionCount: ${paymentObj.transactionCount}
                                type: "Close"
                                description: ""
                                cashToKeep: ${checkedValue === "0" ? salesAmount : cashToKeep}
                                cashEventDetails: [${closedCashEvents}]
                              }
                            ]
                          }`);
                        } else {
                          let cashInOut = [];
                          let cashIn = 0;
                          let cashOut = 0;
                          let pattyCashIn = 0;
                          let pettyCashOut = 0;

                          await db.cashInCashOut.toArray((data) => {
                            data.map((item) => {
                              if (item.type === "cashIn") {
                                cashIn += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "CI",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`);
                              } else if (item.type === "cashOut") {
                                cashOut += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "CO",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`);
                              }
                              if (item.type === "pettyCashIn") {
                                pattyCashIn += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "PCI",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`);
                              }
                              if (item.type === "pettyCashOut") {
                                pettyCashOut += parseFloat(item.amount);
                                cashInOut.push(`{
                                  cwrTillCashInOutId: "${item.key}",
                                  amount: ${parseFloat(item.amount)},
                                  type: "PCO",
                                  time: "${item.date}",
                                  updated: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  created: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}",
                                  description: "${item.note === undefined || item.note === null ? "" : item.note}"
                                }`);
                              }
                            });
                          });
                          await db.cashInCashOut.clear();
                          tillCash.push(`{
                            cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                            date: "${moment(new Date()).format("YYYY-MM-DD")}"
                            finPaymentmethodId: "${paymentObj.finPaymentmethodId}"
                            finFinancialAccount2Id: ${paymentObj.finDayCloseAccountId && parseFloat(paymentObj.amount) !== 0 ? `"${paymentObj.finDayCloseAccountId}"` : null}
                            opening: 0
                            opentime : "${moment(event.openingTime).format("YYYY-MM-DD HH:mm:ss")}"
                            closetime: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                            sales: ${parseFloat(paymentObj.expectedAmount.toFixed(2))}
                            netsales: ${parseFloat(paymentObj.expectedAmount.toFixed(2))}
                            cashin: ${cashIn}
                            cashout: ${cashOut}
                            retainAmt: 0
                            notes: "${noteValue}"
                            closing: ${parseFloat(paymentObj.amount)}
                            returns: ${paymentObj.paymentReturn}
                            iscash: 0
                            isclose: 1
                            pettyCashIn: ${pattyCashIn},
                            pettyCashOut: ${pettyCashOut},
                            cashInOut: [${cashInOut}],
                            cashEvents: [
                              {
                                cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                                amount: ${parseFloat(paymentObj.amount)}
                                expected: ${paymentObj.expectedAmount}
                                diff: ${paymentObj.difference}
                                transactionCount: ${paymentObj.transactionCount}
                                type: "Close"
                                description: ""
                                cashEventDetails: []
                              }
                            ]
                          }`);
                        }
                      }

                      const paramsInput = {
                        query: `mutation{
                          upsertTill(tillInfo:{
                            cwrTillID:"${tillData.tillAccess.cwrTill.cwrTillID}"
                            csbunitID:"${tillData.tillAccess.csBunit.csBunitId}"
                            csclientID:"${tillData.tillAccess.csClientId}"
                            created:"${event.openingTime}"
                            createdby:"${tillData.tillAccess.csUserId}"
                            updated:"${event.openingTime}"
                            updatedby:"${tillData.tillAccess.csUserId}"
                            tillCash:[${tillCash}]
                          })
                          {    
                            status
                            message
                            cwrTillID
                                  tillCash{
                              
                              cwrTillCashId
                              date
                              finPaymentmethodId
                              opening
                              sales
                              netsales
                              cashin
                              cashout
                              retainAmt
                              closing
                              returns
                              iscash
                              notes
                              isclose
                              storeDailyOpsTillid
                              cashEvents{
                                cwrCashEventsID
                                amount
                                expected
                                diff
                                transactionCount
                                type
                                description
                                cashEventDetails{
                                  cwrCashEventdetailsID
                                  count
                                  amount
                                  denomination
                                }
                              }
                            }
                          }
                        }`,
                      };

                      Axios({
                        url: serverUrl,
                        method: "POST",
                        data: paramsInput,
                        headers: {
                          "Content-Type": "Application/json",
                          Authorization: `${authHeaders.access_token}`,
                        },
                      }).then(async (response) => {
                        const result = response.data.data?.upsertTill;
                        const status = result?.status;
                        if (status === "200") {
                          localStorage.removeItem("storeDailyOpsTillid");
                          let tillData = await db.tillRegistrations.toArray();
                          db.ordersData
                          .where("tillStatus")
                          .equalsIgnoreCase("open")
                          .modify((event) => {
                            event.tillStatus = "closed";
                          });
                    
                        db.paymentsData
                          .where("tillStatus")
                          .equalsIgnoreCase("open")
                          .modify((event) => {
                            event.tillStatus = "closed";
                          });
                          db.tillEvents
                          .where("tillStatus")
                          .equalsIgnoreCase("open")
                          .modify((event) => {
                            event.tillStatus = "closed";
                          });
                          Axios({
                            url: serverUrl,
                            method: "POST",
                            data: {
                              query: `mutation {
                                  upsertPOSActivity(tillActivity: [
                                    {
                                      csBunitId: "${tillData[0].tillAccess[0].csBunit.csBunitId}"
                                      csUserId: "${tillData[0].tillAccess[0].csUser.csUserId}"
                                      tillRegistrationId: "${tillData[0].tillHistory[0].cwrTillRegHistoryId}"
                                      type: "LO"
                                      time: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                                    }
                                  ]) {
                                    status
                                    message
                                  }
                              }`,
                            },
                            headers: {
                              "Content-Type": "Application/json",
                              Authorization: `${authHeaders.access_token}`,
                            },
                          }).then(async (response) => {
                            if (response.data.data.upsertPOSActivity.status === 200 || response.data.data.upsertPOSActivity.status === "200") {
                              // await onLogin();
                              if (posConfig.closingRegister === "Y") {
                                let unlinkTill = localStorage.getItem("unlink");
                                if (unlinkTill) {
                                  unlinkTillAPI();
                                } else {
                                  // if (Instance === "Enterprise") {
                                  const cookiesToDelete = document.cookie.split(";").map((cookie) => cookie.trim());

                                  for (let i = 0; i < cookiesToDelete.length; i++) {
                                    const cookie = cookiesToDelete[i];
                                    const eqPos = cookie.indexOf("=");
                                    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                                    const domain = "domain=" + domainURL;
                                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict;" + domain;
                                  }

                                  // }
                                  localStorage.removeItem("tillSession");
                                  window.location.assign("/");
                                  // window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`)
                                }
                                // tillClosingPrint(closingSession);
                                // window.open(`http://localhost:3001/others/window/7515`, "_self");
                                // window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`)
                              }
                            }
                          });
                          db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ isSynced: 1 });
                          try {
                            let unlinkTill = localStorage.getItem("unlink");
                            if (unlinkTill) {
                              unlinkTillAPI();
                            } else {
                              // if (Instance === "Enterprise") {
                              const cookies = document.cookie.split(";");
                              for (let i = 0; i < cookies.length; i++) {
                                const cookie = cookies[i];
                                const eqPos = cookie.indexOf("=");
                                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                                const domain = "domain=" + domainURL;
                                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict;" + domain;
                              }

                              // }
                              localStorage.removeItem("tillSession");
                              window.location.assign("/");
                              // window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`)
                            }
                            // localStorage.clear();
                          } catch (error) {
                            // message.error(error.message);
                          }
                        } else {
                          message.error(result.message)
                          console.log("Failed Till Session Sync ====> ", response);
                          db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ isSynced: 2 });
                        }
                      });
                    }
                  }
                });
            }
          }
        });
      }
    }
  };

  const unlinkTillAPI = async () => {
    try {
      let id = tillData.tillAccess.cwrTill.cwrTillID;

      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
            unlinkTill(tillId: "${id}") {
              status
              message
            } 
          }`,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authHeaders.access_token}`,
        },
      });

      if (response.data.data.unlinkTill.status === "200") {
        const keepKey = ""; 
        localStorage.clear();
        await Promise.all(
          db.tables.map(async (table) => {
            if (table.name !== keepKey) {
              await table.clear();
            }
          })
        );
      }

      localStorage.removeItem("unlink");
      window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`);
    } catch (error) {
      console.error("Error in unlinkTillAPI:", error);
    }
  };

  const handleAddAmount =(value) => {
    if(checkedValue !== "0"){
        if (value === "clear") {
            setCashToKeep("");
        } else if (value === "x") {
            setCashToKeep(`${cashToKeep.toString().substring(0, cashToKeep.toString().length - 1)}`);
        } else {
            setCashToKeep(`${cashToKeep}${value}`)
        }
    }
  }

  return (
    <div style={{ ...data.openTill.mainContainer, backgroundColor: "#F3F4F9" }}>
      <div>
        <Row style={{ height: "6vh", padding: "1% 0 0 1%" }}>
          <Col span={11}>
            <label
              style={{
                fontSize: "2vw",
                color: "#000000",
                letterSpacing: "0.36px",
                opacity: 1,
                fontWeight: "600",
                textAlign: "center",
                paddingLeft: "2vw",
              }}
            >
              Close POS Till
            </label>
          </Col>
          <Col span={13} style={{ display: "flex", alignItems: "end", flexDirection: "column", padding: "0 1vw" }}>
            <span style={{ fontWeight: "600", fontSize: "2vh" }}>{currentDate}</span>
            <span style={{ fontWeight: "600", fontSize: "2vh" }}>{displayClock}</span>
          </Col>
        </Row>

        <div style={{ ...data.openTill.mainCardContainer }}>
          <div style={{ padding: "1vw", display: "flex", paddingBottom: "0" }}>
            <div style={{ borderRadius: "5px", width: "75%", backgroundColor: "#fff", height: "84vh" }}>
              <div
                style={{
                  height: "7vh",
                  width: "100%",
                  backgroundColor: "rgba(146, 144, 152, 0.5)",
                  display: "flex",
                  alignItems: "center",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                }}
              >
                <span style={{ fontSize: "2.8vh", fontWeight: 500, paddingLeft: "3vw", verticalAlign: "middle" }}>Select Cash To Keep</span>
              </div>
              <div style={{ padding: "2vh 3vw" }}>
                <Checkbox onChange={onChange} checked={checkedValue === "Nothing"} value="Nothing" style={{ borderColor: "rgba(146, 144, 152, 0.5)" }}>
                  <span style={{ fontWeight: "500", fontSize: "2.5vh" }}>Nothing</span>
                </Checkbox>
                <div style={{ marginBottom: 16 }} />
                <Checkbox value="0" onChange={onChange} checked={checkedValue === "0"}>
                  <span style={{ fontWeight: "500", fontSize: "2.5vh" }}>{salesAmount}</span>
                </Checkbox>
              </div>
            </div>
            <div style={{ width: "25%", paddingLeft: "2.3%" }}>
              <div style={{...data.openTill.opentillRightCard,paddingTop:"4vh"}}>
              {/* <Form.Item style={{ marginBottom: "10px" }} name="amount"> */}
                    <Input
                     id ="issueCardAmount"
                     placeholder="Enter Amount"
                      // prefix={<UserOutlined style={{color:"#929098"}}/>}
                      size="large"
                      allowClear={false}
                      value={cashToKeep}
                      onChange={(e)=> checkedValue !== "0" ? setCashToKeep(e.target.value) : null}
                      style={{...retailStyles.posScreenStyles.orderHistoryModal.cashManagmentSearchInput,padding:"1vh 1.5vw", height:"9vh",marginBottom:"10px"}}
                    />
                {/* </Form.Item> */}
              </div>
              <Row style={{height:""}} gutter={[8, 6]}>
                        <Col span={8}>
                          <button id="sm-amount-button1" className="amt-dial-btn-cashtoKeep" onClick={(e) =>{ handleAddAmount("1");e.preventDefault();}}>
                            1
                          </button>
                        </Col>
                        <Col span={8}>
                          <button id="sm-amount-button2" className="amt-dial-btn-cashtoKeep" onClick={(e) => {handleAddAmount("2");e.preventDefault()}}>
                            2
                          </button>
                        </Col>
                        <Col span={8}>
                          <button id="sm-amount-button3" className="amt-dial-btn-cashtoKeep" onClick={(e) => {handleAddAmount("3");e.preventDefault()}}>
                            3
                          </button>
                        </Col>
                        {/* <Col span={8}>
                          <button className="amt-dial-btn-cashtoKeep" id="sm-amount-buttonRemove" onClick={(e) => {handleAddAmount("x");e.preventDefault()}}>
                            <img src={CancelArrow} alt="back space" width={25} height={15} />
                          </button>
                        </Col> */}
                        <Col span={8}>
                          <button id="sm-amount-button4" className="amt-dial-btn-cashtoKeep" onClick={(e) => {handleAddAmount("4");e.preventDefault()}}>
                            4
                          </button>
                        </Col>
                        <Col span={8}>
                          <button id="sm-amount-button5" className="amt-dial-btn-cashtoKeep" onClick={(e) => {handleAddAmount("5");e.preventDefault()}}>
                            5
                          </button>
                        </Col>
                        <Col span={8}>
                          <button id="sm-amount-button6" className="amt-dial-btn-cashtoKeep" onClick={(e) => {handleAddAmount("6");e.preventDefault()}}>
                            6
                          </button>
                        </Col>
                        <Col span={8}>
                            <button id="sm-amount-button7" className="amt-dial-btn-cashtoKeep" onClick={(e) => {handleAddAmount("7");e.preventDefault()}}>
                                7
                            </button>
                        </Col>
                            <Col span={8}>
                              <button id="sm-amount-button8" className="amt-dial-btn-cashtoKeep" onClick={(e) => {handleAddAmount("8");e.preventDefault()}}>
                                8
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button0" className="amt-dial-btn-cashtoKeep" onClick={(e) => {handleAddAmount("9");e.preventDefault()}}>
                                9
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button0" className="amt-dial-btn-cashtoKeep" onClick={(e) => {handleAddAmount("0");e.preventDefault()}}>
                                0
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button00" className="amt-dial-btn-cashtoKeep" onClick={(e) => {handleAddAmount(".");e.preventDefault()}}>
                                .
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button." className="amt-dial-btn-cashtoKeep" onClick={(e) => {handleAddAmount("x");e.preventDefault()}}>
                              <img src={CancelArrow} alt="back space" width={20} height={15} />
                              </button>
                            </Col>
                        <Col span={8}>
                            <button
                            onClick={(e) => {handleAddAmount("clear")}}
                              id="sm-amount-addQuantity" className="amt-dial-btn-cashtoKeep">
                              Clear
                            </button>
                            
                        </Col>
                        <Col span={16}>
                        <button
                            onClick={(e) => {e.preventDefault()}}
                              id="sm-amount-addQuantity" className="amt-dial-btn-cashtoKeep">
                              Enter
                            </button>
                        </Col>
                        <Col span={24}>
                        <button
                            onClick={(e) => {excuteCloseTillAll()}}
                              id="sm-amount-addQuantity" className="amt-dial-btn-cashtoKeep-submit">
                              Submit
                            </button>
                        </Col>
                        <Col span={24}>
                        <button
                            onClick={(e) => {history.push("close-till-all")}}
                              id="sm-amount-addQuantity" className="amt-dial-btn-cashtoKeep-back">
                              Back
                            </button>
                        </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashToKeep;
