import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTranslations from './locales/popUpMessages/English.json';
import arabicTranslations from './locales/popUpMessages/Arabic.json';
import hindiTranslations from './locales/popUpMessages/Hindi.json';

const languageSelected = process.env.REACT_APP_LANGUAGE;
i18n.use(initReactI18next).init({
    lng: languageSelected,
    fallbackLng: languageSelected,
    resources: {
        English: { translation: englishTranslations },
        Arabic: { translation: arabicTranslations },
        Hindi: { translation: hindiTranslations },
    },
});

export default i18n;
