import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Form, Input, Progress, Spin, Modal } from "antd";
// import { serverTokenUrl, serverUrl } from "../../constants/config";
import { useGlobalContext } from "../../lib/auth/globalStore";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../lib/auth";
import { timeStamp } from "../../utility/timestamp";
import Axios from "axios";
import db from "../../database";
import { v4 as uuidv4 } from "uuid";
import { clock } from "../../utility/clock";
import "./loder.css";
import loginBackground from "../../assets/images/backgroundImage.png";
import logoIcon from "../../assets/images/cwpos-blue.png";
import data from "../../constants/registrationLogin.json";
import POSLogo from "../../assets/images/NewLogoCW.svg";
import leftLoading from "../../assets/images/opo.gif";
import "antd/dist/antd.css";
import "../style.css";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import { processSync } from "../Sync/syncData";
import moment from "moment";

const Login = () => {
  const { globalStore, setGlobalStore } = useGlobalContext();
  const { percent, promotionPercentage, productPercentage, productCategoryPercentage } = globalStore;
  const serverUrl = process.env.REACT_APP_serverUrl;
  const [till, setTill] = useState();
  const [tillDetails, setTillDetails] = useState();
  const [loading, setLoading] = useState({ status: false, message: "" });
  // const { setAuthTokens } = useAuth();
  const [clock1, setClock1] = useState(clock());
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [posConfig, setPosConfig] = useState({});
  // const [username,setUsername] = useState()
  const history = useHistory();
  const location = useLocation();
  const [logConfig, setLogConfig] = useState({});
  const searchParams = new URLSearchParams(location.search);
  const tillValue = JSON.parse(localStorage.getItem("tillValue"));
  const nameTill = tillValue.name;
  let email = "";
  let Enterprise = "";
  const authHeaders = getOAuthHeaders();
  if (authHeaders && authHeaders.access_token) {
    email = authHeaders.email;
    Enterprise = authHeaders.Enterprise;
  }

  let username;
  let cleanToken;

  useEffect(() => {
    const authHeaders = getOAuthHeaders();

    const performLogin = async () => {
      if (Enterprise) {
        if (authHeaders && authHeaders.username) {
          username = authHeaders.username;
          cleanToken = authHeaders.access_token;
        }
      } else {
        if (authHeaders && authHeaders.email) {
          username = authHeaders.email;
          cleanToken = authHeaders.access_token;
        }
      }

      // Directly call onLogin
      await onLogin(username, cleanToken);
    };

    // Call the asynchronous function
    performLogin();
  }, []);

  const tillEventsData = (tillData) => {
    let tillValue = tillData;
    const nWStatus = navigator.onLine ? "online" : "offline";
    if (nWStatus === "online") {
      db.tillEvents
        .where("isSynced")
        .equals(0)
        .toArray()
        .then((events) => {
          if (events.length > 0) {
            for (let i = 0; i < events.length; i += 1) {
              const event = events[i];
              const tillCash = [];
              for (let j = 0; j < event.allPaymentsData.length; j += 1) {
                const paymentObj = event.allPaymentsData[j];

                if (paymentObj.name.toLowerCase() === "cash") {
                  const openedCashEvents = [];
                  const closedCashEvents = [];

                  for (let x = 0; x < event.openingCashDenominations.length; x += 1) {
                    openedCashEvents.push(`{
                    cwrCashEventdetailsID:"${uuidv4().replace(/-/g, "").toUpperCase()}"
                    count:${event.openingCashDenominations[x].count}
                    amount:${event.openingCashDenominations[x].amount}
                    denomination:${event.openingCashDenominations[x].value}
                  }`);
                  }
                  for (let z = 0; z < event.closingCashDenominations?.length; z += 1) {
                    closedCashEvents.push(`{
                    cwrCashEventdetailsID:"${uuidv4().replace(/-/g, "").toUpperCase()}"
                    count:${event.closingCashDenominations[z].count}
                    amount:${event.closingCashDenominations[z].amount}
                    denomination:${event.closingCashDenominations[z].value}
                  }`);
                  }

                  tillCash.push(`{
                  cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                  date: "${event.closingTime}"
                  finPaymentmethodId: "${paymentObj.finPaymentmethodId}"
                  finFinancialAccount2Id: ${paymentObj.finDayCloseAccountId && parseFloat(event.closingCash) !== 0 ? `"${paymentObj.finDayCloseAccountId}"` : null}
                  opening: ${event.openingCash}
                  sales: ${paymentObj.cashSaleAmount}
                  netsales: ${paymentObj.cashSaleAmount}
                  cashin: 0
                  cashout: 0
                  retainAmt: 0
                  closing: ${event.closingCash}
                  returns: 0
                  iscash: 1
                  isclose: 1
                  cashEvents: [
                    {
                      cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                      amount: ${event.openingCash}
                      expected: 0
                      diff: 0
                      transactionCount: 0
                      type: "Open"
                      description: ""
                      cashEventDetails: [${openedCashEvents}]
                    },
                    {
                      cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                      amount: ${event.closingCash}
                      expected: ${paymentObj.expectedAmount > 0 ? paymentObj.expectedAmount : 0}
                      diff: ${paymentObj.difference}
                      transactionCount: ${paymentObj.transactionCount}
                      type: "Close"
                      description: ""
                      cashEventDetails: [${closedCashEvents}]
                    }
                  ]
                }`);
                } else {
                  tillCash.push(`{
                  cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                  date: "${event.closingTime}"
                  finPaymentmethodId: "${paymentObj.finPaymentmethodId}"
                  finFinancialAccount2Id: ${paymentObj.finDayCloseAccountId && parseFloat(paymentObj.amount) !== 0 ? `"${paymentObj.finDayCloseAccountId}"` : null}
                  opening: 0
                  sales: ${paymentObj.expectedAmount}
                  netsales: ${paymentObj.expectedAmount}
                  cashin: 0
                  cashout: 0
                  retainAmt: 0
                  closing: ${parseFloat(paymentObj.amount)}
                  returns: 0
                  iscash: 0
                  isclose: 1
                  cashEvents: [
                    {
                      cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                      amount: ${parseFloat(paymentObj.amount)}
                      expected: ${paymentObj.expectedAmount}
                      diff: ${paymentObj.difference}
                      transactionCount: ${paymentObj.transactionCount}
                      type: "Close"
                      description: ""
                      cashEventDetails: []
                    }
                  ]
                }`);
                }
              }

              const paramsInput = {
                query: `mutation{
                upsertTill(tillInfo:{
                  cwrTillID:"${tillValue.tillAccess.cwrTill.cwrTillID}"
                  csbunitID:"${tillValue.tillAccess.csBunit.csBunitId}"
                  csclientID:"${tillValue.tillAccess.csClientId}"
                  created:"${event.openingTime}"
                  createdby:"${tillValue.tillAccess.csUserId}"
                  updated:"${event.openingTime}"
                  updatedby:"${tillValue.tillAccess.csUserId}"
                  tillCash:[${tillCash}]
                })
                {    
                  status
                  message
                  cwrTillID
                        tillCash{
                    
                    cwrTillCashId
                    date
                    finPaymentmethodId
                    opening
                    sales
                    netsales
                    cashin
                    cashout
                    retainAmt
                    closing
                    returns
                    iscash
                    isclose
                    storeDailyOpsTillid
                    cashEvents{
                      cwrCashEventsID
                      amount
                      expected
                      diff
                      transactionCount
                      type
                      description
                      cashEventDetails{
                        cwrCashEventdetailsID
                        count
                        amount
                        denomination
                      }
                    }
                  }
                }
              }`,
              };
              Axios({
                url: serverUrl,
                method: "POST",
                data: paramsInput,
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${cleanToken}`,
                },
              }).then((response) => {
                const result = response.data.data?.upsertTill;
                const status = result?.status;
                if (status === "200") {
                  db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ isSynced: 1 });
                } else {
                  console.log("Failed Till Session Sync ====> ", response);
                  db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ isSynced: 2 });
                }
              });
            }
          }
        });

      if (!JSON.parse(localStorage.getItem("posConfig"))) {
        Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `query {
              getPOSConfig(tillId: "${tillValue.cwr_till_id}",name:null) {
                cwrPosConfigId
                name
                posType
                application
                configJson
                PricingRule
                ExpiryDiscount
                activateExpiryDiscount
              }
          }`,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${cleanToken}`,
          },
        }).then((response) => {
          const posConfig = JSON.parse(response.data.data.getPOSConfig[0].configJson);
          localStorage.setItem("posConfig", JSON.stringify(posConfig));
        });
      }
    }
  };

  // const onRegistration = async () => {
  //     const timeMark = timeStamp();
  //     const resData = await Axios({
  //       url: serverUrl,
  //       method: "POST",
  //       data: {
  //         query: `mutation {
  //         tillRegistration(
  //           till: {
  //             searchKey: "${tillValue.search_key}"
  //             created: "${timeMark}"
  //             updated: "${timeMark}"
  //              tillAccess: { csUser: { username: "${Enterprise?username:email}" } }
  //             tillHistory: {
  //               domain: null
  //               sessionid: null
  //               osVersion: null
  //               localIpAddress: null
  //               macAddress: null
  //               processor: null
  //               ram: null
  //               regTimestamp: "${timeMark}"
  //             }
  //           }
  //         ) {
  //           status
  //           message
  //           searchKey
  //           tillAccess {
  //             salesRep
  //             salesRepId
  //             csClientId
  //             csUser {
  //               csUserId
  //             }
  //             cwrTill {
  //               till
  //               searchKey
  //               description
  //               nextAssignedNumber
  //             }
  //             csBunit {
  //               csBunitId
  //               name
  //               cwrSCustomerId
  //               csCurrency {
  //                 csCurrencyId
  //                 currSymbol
  //                 isoCode
  //                 stdPrecision
  //                 cstgPrecision
  //                 prcPrecision
  //               }
  //             }
  //           }
  //           tillHistory {
  //             domain
  //             sessionid
  //             osVersion
  //             localIpAddress
  //             macAddress
  //             processor
  //             ram
  //             regTimestamp
  //             cwrTillRegHistoryId
  //           }
  //         }
  //       }`,
  //       },
  //       headers: {
  //         "Content-Type": "Application/json",
  //         Authorization: `${cleanToken}`,
  //       },
  //     });

  //     const tillData = resData.data.data.tillRegistration;
  //     if (tillData.status === "200") {
  //         await db.tillRegistrations.add(tillData);
  //         localStorage.setItem("documentSequence", tillData.tillAccess[0].cwrTill.nextAssignedNumber);
  //         setLoading({ status: false, message: "" });
  //         Axios({
  //           url: serverUrl,
  //           method: "POST",
  //           data: {
  //             query: `mutation {
  //                 upsertPOSActivity(tillActivity: [
  //                   {
  //                     csBunitId: "${tillData.tillAccess[0].csBunit.csBunitId}"
  //                     csUserId: "${tillData.tillAccess[0].csUser.csUserId}"
  //                     tillRegistrationId: "${tillData.tillHistory[0].cwrTillRegHistoryId}"
  //                     type: "LI"
  //                     time: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
  //                   }
  //                 ]) {
  //                   status
  //                   message
  //                 }
  //             }`,
  //           },
  //           headers: {
  //             "Content-Type": "Application/json",
  //             Authorization: `${authHeaders.access_token}`,
  //           },
  //         }).then(async(response) => {
  //           if(response.data.data.upsertPOSActivity.status === 200 || response.data.data.upsertPOSActivity.status === "200"){
  //             await onLogin();
  //           }
  //         });
  //     } else {
  //       setLoading({ status: false, message: "" });
  //       errorMessage(tillData.message);
  //       console.error(tillData);
  //     }
  // };

  const insertTillOpenActivitity = (tillData) => {
    const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
    const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
    const tillSession = localStorage.getItem("tillSession");
    if (tillSession) {
      if (JSON.parse(tillSession).tillStatus === "open") history.push(tillLayout === 2 && tillaccess?.dineIn === "Y" ? "/table-management" : "/pos");
    } else {
      db.tillRegistrations.toArray().then((reg) => {
        if (reg.length > 0) {
          const curList = reg[0].tillAccess[0].csBunit.csCurrency;
          const csCurrencyId = curList.csCurrencyId;
          localStorage.setItem("csCurrencyId", csCurrencyId);
        }
      });
      const tillSessionId = uuidv4().replace(/-/g, "").toUpperCase();
      const openTillData = {
        tillSessionId: tillSessionId,
        tillStatus: "open",
        openingTime: timeStamp(),
        closingTime: null,
        openingCashDenominations: [
          {
            denomination: 1,
            count: 0,
            amount: 0,
          },
          {
            denomination: 2,
            count: 0,
            amount: 0,
          },
          {
            denomination: 5,
            count: 0,
            amount: 0,
          },
          {
            denomination: 10,
            count: 0,
            amount: 0,
          },
          {
            denomination: 20,
            count: 0,
            amount: 0,
          },
          {
            denomination: 50,
            count: 0,
            amount: 0,
          },
          {
            denomination: 100,
            count: 0,
            amount: 0,
          },
          {
            denomination: 200,
            count: 0,
            amount: 0,
          },
          {
            denomination: 500,
            count: 0,
            amount: 0,
          },
          {
            denomination: 2000,
            count: 0,
            amount: 0,
          },
        ],
        closingCashDenominations: null,
        openingCash: 0,
        closingCash: null,
        totalOpeningAmount: 0,
        totalClosingExpectedAmount: null,
        totalClosingTransactions: null,
        totalClosingAmount: null,
      };
      localStorage.setItem("tillSession", JSON.stringify(openTillData));
      history.push(tillLayout === 2 && tillaccess?.dineIn === "Y" ? "/table-management" : "/pos");
    }
  };

  const onLogin = async () => {
    setLoading({ status: true, message: "" });
    if (username !== null) {
      let posConfigData = {};
      // if (!JSON.parse(localStorage.getItem("posConfig"))) {
      setLoading(true);
      await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query {
              getPOSConfig(tillId: "${tillValue.cwr_till_id}",name:null) {
                cwrPosConfigId
                name
                posType
                application
                configJson
                PricingRule
                ExpiryDiscount
                activateExpiryDiscount
              }
          }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${authHeaders.access_token}`,
        },
      }).then((response) => {
        if(response.data.data.getPOSConfig.length>0){
          posConfigData = JSON.parse(response.data.data.getPOSConfig[0].configJson);
          // console.log(posConfigData)
          localStorage.setItem("posConfig", JSON.stringify(posConfigData));
          setPosConfig(posConfigData);
          setTimeout(() => setLoading(false), 700);
        }else{
          errorMessage("Login Failed", "Error in getting pos configuration");
        }
       
      });

      const resData = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query{
              tillData(user:"${username}"){   
          
                posScanConfigs{
                    cwrPosScanConfigId
                    scanTrxType
                    dataType
                    barcodeLength
                    startsWith
                    endsWith
                    customFormula
                    formula
                }
                loyaltyApply{
                    cwrLoyaltyLevelId
                    name
                    applicableFor
                    prodCategories{
                        mProductCategoryId
                        include
                        }
                    }  
                tillAccess{
                  cwrTillaccessId
                  csClientId
                  csUserId
                  userAccessController
                  cashManagement
                productSync
              salesHistory
              parkedBills
              giftCard
              manualDiscount
              layAway
              unlinkTill
              salesReport
              holdBill
              couponCode
                tillAccessMeta{
                    key
                    value
                }
                  cwrTill{
                     cwrTillID
                     searchKey
                     till
                     description
                     nextAssignedNumber
                     prefix
                     suffix
                     loyaltyProgram
                     accessController 
                     posType
                     manageCash
                     showopeningamount
                     showsalesamount
                     showexpectedamount
                     showdifferenceamount
                     shiftclose
                     shiftopen
                     eReceipt
                     printPreview
                     cashin
                     cashout
                     layAway
                     payNow
                     hardwareController{
                         imageUrl
                         printReceipt
                         weighingScale
                         payment
                         printBarcode
                     }
                     printTemplate{
                      cwrPrinttemplateId
                      name
                      htmlcode
                      htmlcode2
                      xmlcode
                      xmlcode2
                      obController
                    }
                    tillCloseTemplate{
                         cwrPrinttemplateId
                      name
                      htmlcode
                      htmlcode2 
                      xmlcode
                      xmlcode2  
                      obController
                    }
                    kotPrintTemplate{
                         cwrPrinttemplateId
                      name
                      htmlcode
                      htmlcode2 
                      xmlcode
                      xmlcode2
                    }
                    cancelKotPrintTemplate{
                         cwrPrinttemplateId
                      name
                      htmlcode
                      htmlcode2 
                      xmlcode
                      xmlcode2
                    }
                    salesReportTemplate{
                      cwrPrinttemplateId
                   name
                   htmlcode
                   htmlcode2 
                   xmlcode
                   xmlcode2
                   obController
                 }
                    
                  }
                  csBunit{
                    csBunitId
                    name
                    value
                    cwrSCustomerId
                    cwrCsDoctypeId
                    cwrPcatalogueId
                    cwrSpricelistId
                    pCatalogueSaleType{
                      cwrPcatalogueSaletypeId          
                      isPromoApplicable
                      cwrSaletypeId
                    }
                     currencies{
                      csCurrencyId
                      currSymbol
                      isoCode
                      prcPrecision
                      stdPrecision
                      cstgPrecision
                      symbolRightSide
                      denominations{
                          value
                          seqNo
                      }
                      conversions{
                          csCurrencyIDTo
                          currencyFromRate
                         currencyToRate
                         validfromDate
                         validtoDate
                         isoCode
                      }
                  }
                    b2cCustomer{
                      cwrCustomerId
                      code
                      name
                      email
                      mobileNo
                      pincode
                      retlLoyaltyBalance
                      b2cRegisteredstoreId
                      iscredit
                      balancePoints
                      loyaltyLevel{
                      cwrLoyaltyLevelId
                      name
                      accumulationRate
                      redemptionRate
                    }
                
                  sCustomer{
                  sCustomerID
                  customerCategory{
                    sCustomerCateforyId
                    value
                    name
                    description
                }
                  }
                    }
                    paymentMethodList{
                      cWRPaymentMethodID
                      sequenceNo
                      finPaymentmethodId
                      finFinancialAccountId
                      finDayCloseAccountId
                      name
                      integratedPayment
                      isloyalty
                      paymentProvider
                      iscredit 
                      isGiftCard
                      isDefault
                      csCurrencyId
                      isoCode
                    }
                    mWarehouse{
                      mWarehouseID
                      name
                    }
                    customerAddress{
                      sCustomerAddressID
                        line1
                        line2
                        line3
                        fulladdress
                        phone
                        city
                        postalcode
                        csCountry{
                            csCountryID
                            name          
                        }
                        csRegion{
                            csRegionID
                            name
                        }
                    }
                    locations{
                        csBunitLocationId
                        fulladdress
                        phone
                        contactPerson
                    }
                     salesRep{
                        code
                        name
                        salesRepresentId
                    }
                    
                  }
                  
                }
                status
                message
              }
            
            }`,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${cleanToken}`,
        },
      });
      let tillData = resData.data.data.tillData;
      if (tillData.status === "200") {
        if (tillData !== null && tillData !== undefined) {
          const tillAccessIndex = tillData.tillAccess.findIndex((accessTill) => accessTill.cwrTill.till === nameTill);
          if (tillAccessIndex >= 0) {
            tillData.tillAccess = tillData.tillAccess[tillAccessIndex];
            setTillDetails(tillData);
            const CWCoreServicesUrl = process.env.REACT_APP_genericUrl;
            localStorage.setItem("username", username);
            localStorage.setItem("tillData", JSON.stringify(tillData));
            const cashupData = {
              query: `mutation { executeAPIBuilder(apiBuilderId:"65d481aa05bad714f1f1902d", params: ${JSON.stringify(JSON.stringify({tillCashDate:moment(new Date()).format("YYYY-MM-DD")}))})}`,
            };
            Axios({
              url: CWCoreServicesUrl,
              method: "POST",
              data: cashupData,
              headers: {
                "Content-Type": "Application/json",
                Authorization: `${cleanToken}`,
              },
            }).then((response) => {
              const { executeAPIBuilder } = response.data.data;
              localStorage.setItem("openTillData",executeAPIBuilder)
            });
            tillEventsData(tillData);
            // insertTillOpenActivitity(tillData);
            await processSync(tillData, setGlobalStore);
            setLoading({ status: false, message: "" });
            const tillaccess = JSON.parse(tillData.tillAccess.userAccessController);
            const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
            localStorage.setItem("kioskUI", 0);
            if (posConfigData.showTillOpening === "Y") {
              if (tillData.tillAccess.salesRep === "Y") {
                history.push(tillLayout === 2 && tillaccess.dineIn === "Y" ? "/table-management" : "/pos");
              } else {
                if (tillLayout !== 3) {
                }
                syncRestaurantTables();
                if (posConfigData.showDenominations === "Y") {
                  history.push("/open-till");
                } else if (posConfigData.showDenominations === "N") {
                  history.push("/open-till-component");
                }
              }
            } else {
              insertTillOpenActivitity(tillData);
            }
          } else {
            setLoading({ status: false, message: "" });
            errorMessage("Login Failed", "User not configured for this Till");
          }
        } else {
          setLoading({ status: false, message: "" });
          errorMessage("Login Failed", "No Till Access Found");
        }
      } else {
        setLoading({ status: false, message: "" });
        errorMessage("Login Failed", "Till Access Failed");
      }
    }
  };

  const syncRestaurantTables = () => {
    // const tokens = JSON.parse(localStorage.getItem("tokens"));
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    return new Promise(async (restaurantTablesSuccess, restaurantTablesFailure) => {
      try {
        const paramsInput = {
          query: `query{
            getRestaurantTables(tillId:"${tillData.tillAccess.cwrTill.cwrTillID}"){
              cwrFbsectionId
              sectionName
              posTables{
                cwrFbTableId  
                cSBunitID
                cSClientID
                created
                createdby
                csWindowId
                isactive
                updated
                updatedby
                name
                capacity
                cwrFbFloorId
              }
            }  
          }`,
        };
        const response = await Axios({
          url: serverUrl,
          method: "POST",
          data: paramsInput,
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${authHeaders.access_token}`,
          },
        });
        const { getRestaurantTables } = response.data.data;
        if (response.status === 200 && getRestaurantTables.length > 0) {
          let tempData = [];
          let netWorkerror = false;
          let data = [{ value: "all", name: "All" }];
          getRestaurantTables.map((e, index) => {
            e.posTables.map((item) => {
              item.title = item.name;
              item.merge = true;
              item.cwrFbsectionId = e.cwrFbsectionId;
              item.sectionName = e.sectionName;
              item.table = item.name;
              item.color = "#a7c957";
              item.cwrFbsectionId = e.cwrFbsectionId;
              item.statusType = "OPN";
              item.originalIndex = index;
              tempData.push(item);
            });
            data.push({ value: e.cwrFbsectionId, name: e.sectionName });
          });
          await db.sectionTables.clear();
          await db.sectionTables.bulkAdd(data);
          let occupiedData = [];
          await Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `query {
                  getFBOrder(tableId:null,
                  status: "IP") {
                  cSBunitID
                  cSClientID
                  isactive
                  fbOrderId
                  fbTableId
                  fbSectionId
                  sOrderId
                  orderNo
                  guestName
                  guestType
                  referredBy
                  date1
                  status
                  salesRepId
                  guests
                  isCanceled
                  lines{
                  isCanceled
                  cSBunitID
                  cSClientID
                  isactive
                  fbOrderId
                  fbOrderLineId
                  mPoductId
                  mBatchId
                  description
                  csUomId
                  csTaxId
                  line
                  qty
                  unitPrice
                  listPrice
                  lineNet
                  lineTax
                  lineGross
                  sOrderLineId
                  isOrdered
                  meta{
                    key
                    value
                    fbOrderLineMetaId
                    }
                  }
                  }
              }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${authHeaders.access_token}`,
            },
          })
            .then((FbOrderData) => {
              if (FbOrderData?.data?.data?.getFBOrder.length > 0) {
                FbOrderData.data.data.getFBOrder.map(async (ele) => {
                  tempData.map(async (e) => {
                    if (e && e.cwrFbTableId === ele.fbTableId) {
                      e.ordered = "Y";

                      // ---------------------------------------//

                      let tempCart = { total: 0, tax: 0 };
                      let newArray = [];
                      let sOrderID = null;
                      let flag = "Y";
                      let price = 0;
                      let tax = 0;
                      let manualDiscountApplied = 0;
                      ele.lines?.map((item, index) => {
                        let obj = JSON.parse(item?.meta[0]?.value);
                        if (item.isCanceled === "Y") {
                          obj.nettotal = 0;
                          obj.realPrice = 0;
                          obj.salePrice = 0;
                          obj.taxAmount = 0;
                          obj.total = 0;
                          obj.canceled = "Y";
                        } else {
                          flag = "N";
                        }
                        let manulaDiscount = 0;

                        if ((index === ele.lines.length - 1 && flag === "N") || tillData.tillAccess.salesRep === "N") {
                          sOrderID = obj.sOrderID;
                          manulaDiscount = obj.manualDiscountApplied;
                          tempCart.totalQty = obj.totalQty;
                          tempCart.customer = obj.customer;
                        }
                        manualDiscountApplied = obj.manualDiscountApplied;
                        obj.total = obj.total - obj.discount;
                        tempCart.tax += obj.weight * obj.taxAmount;
                        tempCart.total += obj.weight * obj.realPrice - obj.weight * obj.discount;
                        tempCart.manualDiscountApplied = manulaDiscount;
                        tax = tax + obj.weight * obj.taxAmount;
                        price = price + obj.weight * obj.realPrice;
                        obj.tax = item.csTaxId;
                        obj.order = item.isOrdered;
                        newArray.push(obj);
                      });
                      if (tempCart.customer === undefined) tempCart.customer = tillData.tillAccess.csBunit.b2cCustomer;
                      let cartObj = {
                        ...tempCart,
                        items: [...newArray],
                        paid: 0,
                        change: 0,
                        roundOff: 0,
                        payments: [],
                        redemptionPoints: 0,
                        accumulationPoints: 0,
                        creditAmount: 0,
                        sOrderID: sOrderID,
                        discount: manualDiscountApplied,
                      };
                      let fbOrder = {
                        ...ele,
                        cwrFbTableId: ele.fbTableId,
                        isactive: ele.isactive ? "Y" : "N",
                        table: e.table,
                        color: e.color,
                        ordered: e.ordered,
                        waiter: ele.salesRepId,
                        isCanceled: ele.isCanceled,
                        cart: cartObj,
                        fbOrderStatus: "IP",
                        creatdeTime: ele.date1,
                      };

                      await db.fbOrderData
                        .where("fbOrderId")
                        .equals(fbOrder.fbOrderId)
                        .toArray()
                        .then(async (ordersFetched) => {
                          if (ordersFetched.length > 0) {
                            await db.fbOrderData.update(fbOrder.fbOrderId, fbOrder);
                          } else {
                            await db.fbOrderData.add(fbOrder);
                          }
                        });
                      // ---------------------------------------//
                    }
                  });
                });
              }
            })
            .catch((err) => {
              netWorkerror = true;
            });

          await Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `query {
                getTableStatus(tableId: null) {
                cSBunitID
                cSClientID
                isactive
                fbTableId
                fbSectionId
                guestName
                guestType
                referredBy
                status
                salesRepId
                guests
                fbtableStatusId
                }
            }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${authHeaders.access_token}`,
            },
          })
            .then(async (response) => {
              if (response.data.data.getTableStatus.length > 0) {
                occupiedData = [...response.data.data.getTableStatus];
                await Axios({
                  url: serverUrl,
                  method: "POST",
                  data: {
                    query: `query {getTableReservation(tableId: null, date : "${moment(new Date()).format("YYYY-MM-DD")}") {
                    cSBunitID
                    cSClientID
                    isactive
                    fbTableReservationId
                    fbTableId
                    fbSectionId
                    customerName
                    noOfPeople
                    contactNo
                    guestType
                    referredBy
                    reservationTime
                    reservationDate
                    }
                }`,
                  },
                  headers: {
                    "Content-Type": "Application/json",
                    Authorization: `${authHeaders.access_token}`,
                  },
                }).then((res) => {
                  if (res.data.data.getTableReservation.length > 0) {
                    occupiedData.map((e) => {
                      res.data.data.getTableReservation?.map((ele) => {
                        if (e.fbTableId === ele.fbTableId) {
                          e.reservationTime = ele.reservationTime;
                        }
                      });
                    });
                  }
                });
              }
            })
            .catch((err) => {
              netWorkerror = true;
            });
          tempData.map((e) => {
            if (occupiedData.length > 0) {
              occupiedData?.map((ele) => {
                if (e.cwrFbTableId === ele.fbTableId) {
                  if (ele.status === "OCU") {
                    e.color = "#bc4749";
                    e.noOfPersons = ele.guests;
                    e.guestName = ele.guestName;
                    e.guestType = ele.guestType;
                    e.referredBy = ele.referredBy;
                    e.fbtableStatusId = ele.fbtableStatusId;
                    e.statusType = "OCU";
                    e.waiter = ele.salesRepId;
                  } else if (ele.status === "RES") {
                    e.color = "#f2e8cf";
                    e.noOfPersons = ele.guests;
                    e.guestName = ele.guestName;
                    e.guestType = ele.guestType;
                    e.referredBy = ele.referredBy;
                    e.fbtableStatusId = ele.fbtableStatusId;
                    e.reservationTime = ele.reservationTime;
                    e.statusType = "RES";
                    e.waiter = ele.salesRepId;
                  } else if (ele.status === "OPN") {
                    e.color = "#a7c957";
                    e.noOfPersons = "";
                    e.guestName = "";
                    e.guestType = "";
                    e.referredBy = "";
                    e.fbtableStatusId = ele.fbtableStatusId;
                    e.statusType = "OPN";
                    delete e.cart;
                    delete e.fbOrder;
                    delete e.lines;
                    delete e.noOfPersons;
                    delete e.waiter;
                    delete e.salesRepId;
                    delete e.fbOrderId;
                    delete e.guestName;
                    delete e.guests;
                    delete e.guestType;
                    delete e.referredBy;
                    delete e?.fbOrderStatus;
                    delete e.id;
                    // delete e.fbtableStatusId;
                  }
                }
              });
            }
          });
          setTimeout(async () => {
            await db.tableData.clear();
            await db.tableData.bulkPut(tempData);
          }, 500);
        } else {
          restaurantTablesSuccess();
        }
      } catch (error) {
        restaurantTablesFailure(error);
      }
    });
  };

  // const onModalOkay = ()=>{
  //   const keepKey = "userData"; // Replace with the key you want to keep
  //   for (let i = 0; i < localStorage.length; i++) {
  //     const key = localStorage.key(i);
  //     // Check if the current key is not the one you want to keep
  //     if (key !== keepKey) {
  //       localStorage.removeItem(key);
  //     }
  //   }
  //   history.push("/")
  // }
const handleOK = ()=>{
history.push("/")
localStorage.removeItem("tillValue")
}

  const errorMessage = (modalTitle, modalContent) => {
    Modal.error({
      title: modalTitle,
      content: modalContent,
      onOk:handleOK
    });
  };

  const formattedDateTime = currentDateTime.toLocaleDateString("en-US", {
    day: "2-digit", // Two-digit day of the month
    month: "2-digit", // Two-digit month
    year: "numeric", // Full year (e.g., 2023)
  });
  const LoadingOutlinedSpin = <LoadingOutlined style={{ fontSize: 54 }} spin />;

  return (
    <div style={{ backgroundColor: "#F3F4F9", height: "100vh", width: "100%" }}>
      <Row style={{ height: "9vh", background: "", padding: "0.8% 0" }}>
        <Col span={6}>
          <img src={POSLogo} style={{ height: "2.8vw", paddingLeft: "1vw", paddingTop: "1vh" }} />
        </Col>
        <Col span={18} style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", paddingRight: "1vw" }}>
          <div style={{ fontWeight: 400, fontFamily: "Inter", fontSize: "1.2vw" }}>{formattedDateTime}</div>
          <div style={{ fontWeight: 400, fontFamily: "Inter", fontSize: "1.2vw" }}>{clock1}</div>
        </Col>
      </Row>
      <div style={{ display: "flex", height: "90vh" }}>
        <div
          style={{
            width: "48%",
            background: "#fff",
            height: "83vh",
            margin: "1.5%",
            marginRight: 0,
            borderRadius: "10px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ position: "relative", width: "13vw", height: "13vw" }}>
              <img
                src={leftLoading}
                alt="Loading"
                style={{
                  width: "100%",
                  height: "100%",
                  // transform: `rotate(${rotation}deg)`,
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "1.5vw",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                {percent}
              </div>
            </div>
            <p style={{ color: "#0F0718", fontFamily: "Inter", fontWeight: 500, fontSize: "2.7vw", marginBottom: 0 }}>Till loading in process</p>
            {/* <p style={{color:"#D73A48",fontFamily:"Inter",fontWeight:500,fontSize:"1.5vw",marginBottom:0}}>{formatTime(time)}</p>
  <p style={{color:"#0F0718",fontFamily:"Inter",fontWeight:500,fontSize:"1.5vw"}}>Minutes Left</p> */}
          </div>
        </div>
        <div style={{ width: "48%", height: "83vh", background: "#fff", margin: "1.5%", borderRadius: "10px" }}>
          <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1vw", marginTop: "17vh", marginBottom: 0, padding: "0 2.5vw" }}>Product Categories</p>
          <Progress
            strokeColor={{
              from: "#2F3856",
              to: "#2F3856",
            }}
            // showInfo={false}
            style={{ padding: "0 2.5vw" }}
            percent={productCategoryPercentage}
            status="active"
          />
          <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1vw", marginTop: "5vh", marginBottom: 0, padding: "0 2.5vw" }}>Products</p>
          <Progress
            strokeColor={{
              from: "#2F3856",
              to: "#2F3856",
            }}
            // showInfo={false}
            style={{ padding: "0 2.5vw" }}
            percent={productPercentage}
            status="active"
          />
          <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1vw", marginTop: "5vh", marginBottom: 0, padding: "0 2.5vw" }}>Promotions</p>
          <Progress
            strokeColor={{
              from: "#2F3856",
              to: "#2F3856",
            }}
            // showInfo={false}
            style={{ padding: "0 2.5vw" }}
            percent={promotionPercentage}
            status="active"
          />
        </div>
      </div>
    </div>
    // <Spin indicator={LoadingOutlinedSpin} spinning={loading.status} style={{ marginTop: "38vh", marginLeft: "50%" }}></Spin>
  );
};

export default Login;
