import { LoadingOutlined } from "@ant-design/icons";
import Axios from "axios";
import db from "../../database";
import data from "../../constants/sync.json";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import { v4 as uuidv4 } from "uuid";
import { socket } from "../../socket";
import moment from "moment";

let cleanToken;
const serverUrl = process.env.REACT_APP_serverUrl;
const CWCoreServicesUrl = process.env.REACT_APP_genericUrl;
const authHeaders = getOAuthHeaders();
if (authHeaders) {
  cleanToken = authHeaders.access_token;
}

if (process.env.REACT_APP_Websocket === "false") {
  socket.close();
}

export const processSync = async (data, setGlobalStore) => {
  let tillData = data ? data : JSON.parse(localStorage.getItem("tillData"));
  try {
    let startTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    await deletePreviousData();
    setGlobalStore({ percent: "15%" });
    setGlobalStore({ productCategoryPercentage: "38" });
    await syncAllProductCategories(tillData);
    setGlobalStore({ percent: "30%" });
    setGlobalStore({ productCategoryPercentage: "55" });
    setGlobalStore({ productPercentage: "15" });
    await syncProductCategories(tillData);
    setGlobalStore({ percent: "45%" });
    setGlobalStore({ productCategoryPercentage: "70" });
    setGlobalStore({ productPercentage: "30" });
    await syncPosSaleTypes(tillData);
    setGlobalStore({ percent: "53%" });
    setGlobalStore({ productCategoryPercentage: "100" });
    setGlobalStore({ productPercentage: "47" });
    await syncProductsUom(tillData);
    setGlobalStore({ percent: "64%" });
    setGlobalStore({ productPercentage: "56" });
    await syncPricingRules(tillData);
    setGlobalStore({ percent: "75%" });
    setGlobalStore({ productPercentage: "69" });
    await syncProducts(tillData);
    setGlobalStore({ percent: "84%" });
    setGlobalStore({ promotionPercentage: "25" });
    setGlobalStore({ productPercentage: "77" });
    await syncOfflineDta(tillData);
    setGlobalStore({ percent: "90%" });
    setGlobalStore({ promotionPercentage: "55" });
    setGlobalStore({ productPercentage: "85" });
    await syncPosConfigData(tillData);
    setGlobalStore({ percent: "95%" });
    setGlobalStore({ productPercentage: "100" });
    setGlobalStore({ promotionPercentage: "79" });
    await syncLoyalityData();
    await syncGiftCardData();
    await getApprovals();
    await syncRFIDData(tillData);
    setGlobalStore({ percent: "99%" });
    setGlobalStore({ promotionPercentage: "100" });
    startTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    localStorage.setItem("lastUpdatedTime", startTime);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const syncOfflineDta = (tillData) => {
  const nWStatus = navigator.onLine ? "online" : "offline";
  if (nWStatus === "online") {
    db.tillEvents
      .where("isSynced")
      .equals(0)
      .toArray()
      .then((events) => {
        if (events.length > 0) {
          for (let i = 0; i < events.length; i += 1) {
            const event = events[i];
            const tillCash = [];
            for (let j = 0; j < event.allPaymentsData.length; j += 1) {
              const paymentObj = event.allPaymentsData[j];

              if (paymentObj.name.toLowerCase() === "cash") {
                const openedCashEvents = [];
                const closedCashEvents = [];

                for (let x = 0; x < event.openingCashDenominations.length; x += 1) {
                  openedCashEvents.push(`{
                    cwrCashEventdetailsID:"${uuidv4().replace(/-/g, "").toUpperCase()}"
                    count:${event.openingCashDenominations[x].count}
                    amount:${event.openingCashDenominations[x].amount}
                    denomination:${event.openingCashDenominations[x].value}
                  }`);
                }
                for (let z = 0; z < event.closingCashDenominations?.length; z += 1) {
                  closedCashEvents.push(`{
                    cwrCashEventdetailsID:"${uuidv4().replace(/-/g, "").toUpperCase()}"
                    count:${event.closingCashDenominations[z].count}
                    amount:${event.closingCashDenominations[z].amount}
                    denomination:${event.closingCashDenominations[z].value}
                  }`);
                }

                tillCash.push(`{
                  cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                  date: "${event.closingTime}"
                  finPaymentmethodId: "${paymentObj.finPaymentmethodId}"
                  finFinancialAccount2Id: ${paymentObj.finDayCloseAccountId && parseFloat(event.closingCash) !== 0 ? `"${paymentObj.finDayCloseAccountId}"` : null}
                  opening: ${event.openingCash}
                  sales: ${paymentObj.cashSaleAmount}
                  netsales: ${paymentObj.cashSaleAmount}
                  cashin: 0
                  cashout: 0
                  retainAmt: 0
                  closing: ${event.closingCash}
                  returns: 0
                  iscash: 1
                  isclose: 1
                  cashEvents: [
                    {
                      cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                      amount: ${event.openingCash}
                      expected: 0
                      diff: 0
                      transactionCount: 0
                      type: "Open"
                      description: ""
                      cashEventDetails: [${openedCashEvents}]
                    },
                    {
                      cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                      amount: ${event.closingCash}
                      expected: ${paymentObj.expectedAmount}
                      diff: ${paymentObj.difference}
                      transactionCount: ${paymentObj.transactionCount}
                      type: "Close"
                      description: ""
                      cashEventDetails: [${closedCashEvents}]
                    }
                  ]
                }`);
              } else {
                tillCash.push(`{
                  cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                  date: "${event.closingTime}"
                  finPaymentmethodId: "${paymentObj.finPaymentmethodId}"
                  finFinancialAccount2Id: ${paymentObj.finDayCloseAccountId && parseFloat(paymentObj.amount) !== 0 ? `"${paymentObj.finDayCloseAccountId}"` : null}
                  opening: 0
                  sales: ${paymentObj.expectedAmount}
                  netsales: ${paymentObj.expectedAmount}
                  cashin: 0
                  cashout: 0
                  retainAmt: 0
                  closing: ${parseFloat(paymentObj.amount)}
                  returns: 0
                  iscash: 0
                  isclose: 1
                  cashEvents: [
                    {
                      cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                      amount: ${parseFloat(paymentObj.amount)}
                      expected: ${paymentObj.expectedAmount}
                      diff: ${paymentObj.difference}
                      transactionCount: ${paymentObj.transactionCount}
                      type: "Close"
                      description: ""
                      cashEventDetails: []
                    }
                  ]
                }`);
              }
            }

            const paramsInput = {
              query: `mutation{
                upsertTill(tillInfo:{
                  cwrTillID:"${tillData.tillAccess.cwrTill.cwrTillID}"
                  csbunitID:"${tillData.tillAccess.csBunit.csBunitId}"
                  csclientID:"${tillData.tillAccess.csClientId}"
                  created:"${event.openingTime}"
                  createdby:"${tillData.tillAccess.csUserId}"
                  updated:"${event.openingTime}"
                  updatedby:"${tillData.tillAccess.csUserId}"
                  tillCash:[${tillCash}]
                })
                {    
                  status
                  message
                  cwrTillID
                        tillCash{
                    
                    cwrTillCashId
                    date
                    finPaymentmethodId
                    opening
                    sales
                    netsales
                    cashin
                    cashout
                    retainAmt
                    closing
                    returns
                    iscash
                    isclose
                    storeDailyOpsTillid
                    cashEvents{
                      cwrCashEventsID
                      amount
                      expected
                      diff
                      transactionCount
                      type
                      description
                      cashEventDetails{
                        cwrCashEventdetailsID
                        count
                        amount
                        denomination
                      }
                    }
                  }
                }
              }`,
            };
            Axios({
              url: serverUrl,
              method: "POST",
              data: paramsInput,
              headers: {
                "Content-Type": "Application/json",
                Authorization: `${cleanToken}`,
              },
            }).then((response) => {
              const result = response.data.data?.upsertTill;
              const status = result?.status;
              if (status === "200") {
                if (tillData.tillAccess.salesRep === "Y") {
                  localStorage.setItem("dineIn", "N");
                }
                db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ isSynced: 1 });
              } else {
                db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ isSynced: 2 });
              }
            });
          }
        }
      });
  }
};

export const deletePreviousData = () => {
  return new Promise(async (deletionSuccess, deletionFailure) => {
    let lastSyncTime = localStorage.getItem("lastUpdatdeTime");
    try {
      await db.productCategories.clear();
      await db.AllProductCategories.clear();
      //   await db.restaurantTables.clear();
      if (lastSyncTime !== null) {
        await db.products.clear();
      }
      await db.pricingRules.clear();
      await db.posSaletypes.clear();
      await db.productUom.clear();
      await db.rfidData.clear();
      await db.loyalityData.clear();
      await db.POSWorkFlowRules.clear();
      await db.approvers.clear();
      await db.giftCardData.clear();
      deletionSuccess();
    } catch (error) {
      deletionFailure(error);
    }
  });
};

export const syncProductsUom = (tillData) => {
  return new Promise(async (uomSyncSuccess, uomSyncFailure) => {
    try {
      const paramsInput = {
        query: `query{
              getUom{
                csUomId
                costingprecision
                description
                ediCode
                name
                stdprecision
                symbol
                decimal
              }
            }`,
      };
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });
      const { getUom } = response.data.data;
      if (response.status === 200 && getUom.length > 0) {
        const lastId = await db.productUom.bulkAdd(getUom);
        console.log("POS Uom: ", " Synced");
        uomSyncSuccess(lastId);
      } else {
        uomSyncSuccess();
        console.log("POS Uom: ", getUom);
      }
    } catch (error) {
      console.log("POS Uom: ", " Sync Failed");
      uomSyncFailure(error);
    }
  });
};

export const syncPosSaleTypes = (tillData) => {
  return new Promise(async (posSaleTypeSyncSuccess, posSaleTypeSyncFailure) => {
    try {
      const paramsInput = {
        query: `query{
              getPosSaletype(catalogueId:"${tillData.tillAccess.csBunit.cwrPcatalogueId}"){
                cwrPcatalogueSaletypeId
                cSClientID
                cSBunitID
                created
                createdby
                updated
                updatedby
                isactive
                isPromoApplicable
                cwrPCatalogueId
                cwrSaletype{
                  cwrSaletypeId
                  value
                  name
                  isdefault
                }
              }
            }`,
      };
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });
      const { getPosSaletype } = response.data.data;
      if (response.status === 200 && getPosSaletype.length > 0) {
        const lastId = await db.posSaletypes.bulkAdd(getPosSaletype);
        console.log("POS Sale Types: ", " Synced");
        posSaleTypeSyncSuccess(lastId);
      } else {
        posSaleTypeSyncSuccess();
      }
    } catch (error) {
      console.log("POS Sale Types: ", " Sync Failed");
      posSaleTypeSyncFailure(error);
    }
  });
};

export const syncAllProductCategories = (tillData) => {
  return new Promise(async (posSaleTypeSyncSuccess, posSaleTypeSyncFailure) => {
    try {
      const paramsInput = {
        query: `query{
          getProductCategory(tillId:"${tillData.tillAccess.cwrTill.cwrTillID}"){
              mProductCategoryId
              name
              value
              description
              imageurl
              parentCategory{
                  mProductCategoryId
                  name
                  value
                  description
                  imageurl
                  parentCategory{
                      mProductCategoryId
                      name
                      value
                      description
                      imageurl
                      parentCategory{
                          mProductCategoryId
                          name
                          value
                          description
                          imageurl
                          parentCategory{
                              mProductCategoryId
                              name
                              value
                              description
                              imageurl
                          }
                      }
                  }
              }
          }
        }`,
      };
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });
      const { getProductCategory } = response.data.data;
      if (response.status === 200 && getProductCategory.length > 0) {
        console.log("POS Sale Types: ", " Synced");

        let isArray = [];
        let finalArray = [];
        const seenNames = new Set();

        function getParentCategoryValue(obj) {
          if (!obj.parentCategory) {
            const id = obj.mProductCategoryId;
            if (!seenNames.has(id)) {
              isArray.push(obj);
              seenNames.add(id);
            }
            return id;
          }
          obj.id = obj.parentCategory.mProductCategoryId;
          finalArray.push({
            mProductCategoryId: obj.mProductCategoryId,
            name: obj.name,
            value: obj.value,
            description: obj.description,
            imageurl: obj.imageurl,
            id: obj.id,
          });

          // console.log("obj.parentCategory============>",obj.parentCategory)
          return getParentCategoryValue(obj.parentCategory);
        }

        for (let i = 0; i < getProductCategory.length; i++) {
          const obj = getProductCategory[i];
          const parentCategoryValue = getParentCategoryValue(obj);
          // console.log(`Object ${i}: Parent category value: ${parentCategoryValue}`);
        }

        // ===============================
        const seenId = new Set();
        isArray.map((item) => {
          const printArray = finalArray.reduce((result, current) => {
            const category = current.id;
            if (!result[category]) {
              result[category] = [];
            }
            const id = current.mProductCategoryId;
            if (!seenId.has(id)) {
              seenId.add(id);
              result[category].push(current);
            }
            return result;
          }, {});
          item.printArray = printArray;
        });
        const lastId = await db.AllProductCategories.bulkAdd(isArray);
        posSaleTypeSyncSuccess(lastId);
      } else {
        posSaleTypeSyncSuccess();
      }
    } catch (error) {
      console.log("POS Sale Types: ", " Sync Failed");
      posSaleTypeSyncFailure(error);
    }
  });
};

export const syncProductCategories = (tillData) => {
  localStorage.setItem("sync1", true);
  return new Promise(async (productCategorySyncSuccess, productCategorySyncFailure) => {
    try {
      const paramsInput = {
        query: `query {
            productCategory(bunit:"${tillData.tillAccess.csBunit.csBunitId}"){
            mProductCategoryId
            value
            name
            description
            imageurl
            }
          }`,
      };
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });
      const { productCategory } = response.data.data;
      if (response.status === 200 && productCategory.length > 0) {
        const lastId = await db.productCategories.bulkAdd(productCategory);
        console.log("Product Category: ", " Synced");
        syncProductBrand();
        productCategorySyncSuccess(lastId);
      } else {
        productCategorySyncSuccess();
      }
    } catch (error) {
      console.log("Product Category: ", " Sync Failed");
      productCategorySyncFailure(error);
    }
  });
};

export const syncProductBrand = (tillData) => {
  localStorage.setItem("sync1", true);
  let userData = JSON.parse(localStorage.getItem("userData"));
  return new Promise(async () => {
    try {
      const paramsInput = {
        query: `query{
          getBrand(clientId:"${userData.cs_client_id}"){
            brandId
            name
            value
          }  
        }`,
      };
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });
      console.log(response);
      const { getBrand } = response.data.data;
      if (response.status === 200 && getBrand.length > 0) {
        await db.productBrands.bulkAdd(getBrand);
      }
    } catch (error) {
      console.log("Product Category: ", " Sync Failed");
      // productCategorySyncFailure(error);
    }
  });
};

export const syncPricingRules = (tillData) => {
  return new Promise(async (pricingRulesSyncSuccess) => {
    try {
      const paramsInput = {
        query: `query {
          getPricingRules(catalogueId: "${tillData.tillAccess.csBunit.cwrPcatalogueId}"){
              mPricingrulesId
              csClientId
              csBunitId
              created
              createdBy
              updated
              updatedBy
              upc
              type
              discountType
              name
              printedName
              description
              startDate
              endDate
              nextRule
              percentageDiscount
              amountDiscount
              minimumQty
              maximumQty
              xQty
              yQty
              billAmount
              maxBillAmount
              cwrSaletypeId
              fixedUnitPrice
              timeSpecific
              starttime
              endtime
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
              status
              iscoupon
              nextRule
              priority
              giftVoucherType
              issueGiftVoucher
              manualDiscount
              excludeProductCategories
              excludeBusinessUnits
              excludeProducts
              excludeB2CCustomers
              excludeBrands
              excludeCustomers
              excludeCustomerCategory
              excludeB2CSegment
              mPricingXProducts {
                  mPricingXProductId
                  line
                  mProductId
                  mBatchId
                  isFree
                  quantity
              }
              mPricingYProducts {
                  mPricingYProductId
                  line
                  mProductId
              }
              mPricingCcategories {
                  mPricingCcategoryId
                  line
                  sCustomerCategoryId
              }
              mPricingPcategories {
                  mPricingPcategoryId
                  line
                  mProductCategoryId
              }
              mPricingBrands {
                  mPricingBrandId
                  line
                  mBrandId
              }
              mPricingBUnits {
                  mPricingBUnitId
                  line
                  mBunitPricingId
              }
              mPricingQuantities {
                  mPricingQuantityId
                  line
                  quantity
                  discountValue
              }
              mPricingB2CCustomers {
                  mPricingB2cCustomerId
                  lineno
                  b2cCustomerId
              }
              mPricingB2CCustomerSegments {
                  mPricingB2CSegmentId
                  lineNo
                  cwrB2CCustomerSegmentId
              }
              mPricingExpiryDiscount {
                rangeFrom
                rangeTo
                discountPercentage
                mProductId
            }
          }
      }`,
      };
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });
      const { getPricingRules } = response.data.data;
      if (response.status === 200 && getPricingRules.length > 0) {
        const lastId = await db.pricingRules.bulkAdd(getPricingRules);
        console.log("Product Pricing Rules: ", " Synced");
        pricingRulesSyncSuccess(lastId);
      } else {
        console.log("Product Pricing Rules: ", getPricingRules);
        pricingRulesSyncSuccess();
      }
    } catch (error) {
      console.log("Product Pricing Rules: ", " Sync Failed");
      pricingRulesSyncSuccess();
    }
  });
};

export const syncProducts = async (tillData) => {
  let lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  try {
    let from = 0;
    let limit = 5000;
    let productsSynced = false;
    while (!productsSynced) {
      const paramsInput = {
        query: `query {
                getProducts1(bUnit:"${tillData.tillAccess.csBunit.csBunitId}", lastSyncTime: ${lastUpdatedTime ? `"${lastUpdatedTime}"` : null}, from:${from} limit:${limit}) {
                  mProductId
                  csClientId
                  csBunitId
                  created
                  createdby
                  updated
                  isactive
                  updatedby
                  sunitprice
                  slistprice
                  onhandQty
                  description
                  isVirtual
                  isBestSeller
                  cTaxId
                  taxRate
                  isPromoApplicable
                  isavailable
                  ismonday
                  istuesday
                  iswednesday
                  isthursday
                  isfriday
                  issaturday
                  issunday
                  colorcode
                  cwrMenuTimeslot {
                    cwrMenuTimeslotId
                    name
                    startTime
                    endTime
                  }
                  mProduct {
                    value
                    name
                    name2
                    csTaxcategoryId
                    mProductCategoryId
                    csUomId
                    uomName
                    upc
                    batchedProduct
                    isManualQty
                    isDecimal
                    imageurl
                    shortDescription
                    hsncode
                    returnable
                    returnDays
                    description
                    batchedForSale
                    batchedForStock
                    multiPrice
                    shelfLife
                    isBag
                    productSegment
                    taxCategory {
                      csTaxcategoryID
                      name
                      overRideTax
                      overRideCondition
                      contraTaxCategoryId
                      contraTaxCategory
                      {
                        contraTaxCategoryName
                        contraTaxId
                        contraTaxName
                        contraRate
                      }
                    }
                    mBatch {
                      mBatchId
                      batchno
                      upc
                      price
                      listPrice
                      startdate
                      enddate
                      life
                    }
                    productGroup {
                      mProductGroupId
                      name
                      value
                      description
                    }
                    productAddons {
                      mProductAddOnId
                      name
                      price
                      mAddonGroup {
                        mAddonGroupId
                        name
                        maxqty
                        minqty
                        type
                      }
                      mAddonProduct {
                        mProductId
                        name
                      }
                    }
                    productAttributes {
                      mProductAttributeId
                      value
                      mAttributeGroup {
                        mAttributeGroupId
                        name
                      }
                      mAttribute {
                        mAttributeId
                        name
                      }
                    }
                    productManufacturer {
                      mProductManufacturerId
                      name
                      value
                      description
                    }
                    productBrand {
                      brandId
                      name
                      value
                      description
                    }
                  }
                }
              }`,
      };
      from += 5000;
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });
      const { getProducts1 } = response.data.data;
      const products = getProducts1;
      const productsLength = products.length;

      if (productsLength > 0) {
        const productsData = [];
        for (let i = 0; i < productsLength; i += 1) {
          const upcIndex = [];
          const batchIndex = [];
          if (products[i].mProduct.mBatch !== null) {
            for (let j = 0; j < products[i].mProduct.mBatch.length; j += 1) {
              batchIndex.push(products[i].mProduct.mBatch[j].batchno);
              upcIndex.push(products[i].mProduct.mBatch[j].upc);
            }
          }
          if (products[i].mProduct.upc !== null) {
            upcIndex.push(products[i].mProduct.upc);
          }
          let uomData = [];
          await db.productUom
            .where("csUomId")
            .equals(products[i].mProduct.csUomId)
            .toArray()
            .then((uom) => {
              if (uom.length > 0) {
                uomData = uom;
              }
            });
          const productDataObj = {
            mProductId: products[i].mProductId,
            csClientId: products[i].csClientId,
            csBunitId: products[i].csBunitId,
            created: products[i].created,
            createdby: products[i].createdby,
            updated: products[i].updated,
            isactive: products[i].isactive,
            updatedby: products[i].updatedby,
            sunitprice: products[i].sunitprice,
            slistprice: products[i].slistprice,
            onhandQty: products[i].onhandQty,
            description: products[i].description,
            shortDescription: products[i].mProduct.shortDescription,
            isVirtual: products[i].isVirtual,
            isBestSeller: products[i].isBestSeller,
            cTaxId: products[i].cTaxId,
            taxRate: products[i].taxRate,
            isPromoApplicable: products[i].isPromoApplicable,
            value: products[i].mProduct.value,
            name: products[i].mProduct.name,
            name2: products[i].mProduct.name2,
            brandId: products[i].mProduct.productBrand.brandId,
            csTaxcategoryId: products[i].mProduct.csTaxcategoryId,
            mProductCategoryId: products[i].mProduct.mProductCategoryId,
            csUomId: products[i].mProduct.csUomId,
            uomName: products[i].mProduct.uomName,
            uomData: uomData,
            upc: products[i].mProduct.upc,
            batchedProduct: products[i].mProduct.batchedProduct,
            batchedForSale: products[i].mProduct.batchedForSale,
            batchedForStock: products[i].mProduct.batchedForStock,
            isManualQty: products[i].mProduct.isManualQty,
            isDecimal: products[i].mProduct.isDecimal,
            imageurl: products[i].mProduct.imageurl,
            productSegment:products[i].mProduct?.productSegment ? products[i].mProduct.productSegment : "",
            // imageurl: DefaultProductImage,
            taxCategory: products[i].mProduct.taxCategory.name,
            mBatch: products[i].mProduct.mBatch,
            hsncode: products[i].mProduct.hsncode,
            batchIndex: batchIndex,
            upcIndex: upcIndex,
            productManufacturerName: products[i].mProduct.productManufacturer.name,
            productManufacturerId: products[i].mProduct.productManufacturer.mProductManufacturerId,
            productBrandName: products[i].mProduct.productBrand.name,
            productBrandId: products[i].mProduct.productBrand.brandId,
            iscustomizable: products[i].mProduct?.iscustomizable,
            mProductGroupId: products[i]?.mProduct?.productGroup?.mProductGroupId || "",
            productCategoryName: products[i]?.mProduct?.productGroup?.name || "",
            productAddons: products[i]?.mProduct?.productAddons || [],
            multiPrice: products[i].mProduct.multiPrice,
            shelfLife: products[i].mProduct.shelfLife,
            overRideTax: products[i].mProduct.taxCategory.overRideTax,
            overRideCondition: parseFloat(products[i].mProduct.taxCategory.overRideCondition),
            contraRate: parseFloat(products[i].mProduct.taxCategory.contraTaxCategory?.contraRate),
            contraTaxId: products[i].mProduct.taxCategory.contraTaxCategory?.contraTaxId,
          };
          productsData.push(productDataObj);
        }
        if (lastUpdatedTime !== null) {
          await Promise.all(
            productsData.map(async (item) => {
              await db.products
                .where("value")
                .equals(item.value)
                .toArray()
                .then(async (response) => {
                  if (response.length > 0) {
                    await db.products.where("value").equals(item.value).modify(item);
                  } else {
                    await db.products.add(item);
                  }
                });
            })
          );
        } else {
          const lastId = await db.products.bulkAdd(productsData);
          console.log("Synced products count:", lastId);
        }
      } else {
        console.log("Products: Synced");
        productsSynced = true;
      }
    }
    // Code to be executed after syncProducts completion
  } catch (error) {
    console.log("Products: Sync Failed");
    throw error;
  }
};

export const syncPosConfigData = async (tillData) => {
  let tillId = JSON.parse(localStorage.getItem("tillValue"));
  await Axios({
    url: serverUrl,
    method: "POST",
    data: {
      query: `query {
        getPOSConfig(tillId: "${tillId.cwr_till_id}",name:null) {
          cwrPosConfigId
          name
          posType
          application
          configJson
          PricingRule
          ExpiryDiscount
          activateExpiryDiscount
        }
    }`,
    },
    headers: {
      "Content-Type": "Application/json",
      Authorization: `${cleanToken}`,
    },
  }).then((response) => {
    let posConfigData = JSON.parse(response.data.data.getPOSConfig[0].configJson);
    localStorage.setItem("expiryDiscount", response.data.data.getPOSConfig[0].activateExpiryDiscount);
    localStorage.setItem("posConfig", JSON.stringify(posConfigData));
  });
};

export const syncRFIDData = async (tillData) => {
  const value = { tag_value: "" };
  const newStringifiedFields = JSON.stringify(value);
  const newUpdatedStrings = newStringifiedFields.replace(/\\"/g, '\\"');
  const newStringRequest = JSON.stringify(newUpdatedStrings);
  const todaySalesInput = {
    query: `mutation { executeAPIBuilder(apiBuilderId:"64d323e6291b3b33ff234dd9", params: ${newStringRequest}) }`,
  };

  await Axios({
    url: CWCoreServicesUrl,
    method: "POST",
    data: todaySalesInput,
    headers: {
      "Content-Type": "Application/json",
      Authorization: `${cleanToken}`,
    },
  }).then(async (response) => {
    const { executeAPIBuilder } = response.data.data;
    const newResponse = JSON.parse(executeAPIBuilder) ? JSON.parse(executeAPIBuilder) : [];
    await db.rfidData.bulkAdd(newResponse);
  });
};

export const syncLoyalityData = async () => {
  const loyalityQuery = {
    query: `query {
            getLoyaltyconfigurations {
              loyaltylevelId
              name
              accumulationRate
              redemptionRate
              applicableFor
              isDefault
              levelId
              validityPeriod
              minPoints
              maxPoints
              sequence
              upgradeThreshold
              downgrade_threshold
              benefits
              LoyaltyAccumulation {
                loyaltyAccumulationId
              name
              description
              loyaltyLevelId
              pointsPerUnit
              mProductId
              product
              mProductCategoryId
              productCategory
              minPurchase
              startDate
              endDate
              loyaltyEvent
              }
              loyaltyRedemption {
                loyaltyRedemptionId
              ruleName
              description
              loyaltyLevelId
              pointsRequired
              redemptionValue
              minPurchase
              max_redemption
              startDate
              endDate
              }
              loyaltyEventCalenders {
                loyaltyEventCalenderId
              name
              description
              eventType
              startDate
              endDate
              }
            }
          }`,
        };

  await Axios({
    url: serverUrl,
    method: "POST",
    data: loyalityQuery,
    headers: {
      "Content-Type": "Application/json",
      Authorization: `${cleanToken}`,
    },
  }).then(async(response) => {
    await db.loyalityData.bulkAdd(response.data.data.getLoyaltyconfigurations);
  })
};

export const syncGiftCardData = async () => {
  const giftQuery = {
    query: `query {
      getGiftCardType{
          cwrGiftcardTypeId
          name
          mProductId
          product
          type
          singleUse
          validity
          balanceLimit
          topupLimit
          prefix
          lengthCardNo
          pinRequired
          printTemplate {
               cwrPrinttemplateId
               name
               htmlcode
               htmlcode2
               xmlcode
               xmlcode2
               obController

           }
        }
    }`,
        };

  await Axios({
    url: serverUrl,
    method: "POST",
    data: giftQuery,
    headers: {
      "Content-Type": "Application/json",
      Authorization: `${cleanToken}`,
    },
  }).then(async(response) => {
    // console.log();
    await db.giftCardData.bulkAdd(response.data.data.getGiftCardType);
  })
};

export const getApprovals = async () => {
  try {
      const paramsInput = {
        query: `query {
          getPOSWorkflowRules{
              rules{
              cwrRulesId
             ruleName
             ruleCondition
             rulePriority
              cwrEventId
             eventId
             eventName}
             approvers {
                 name
                 pin
                 csUserId
                 csBunitId
                 bunitName
                 role
             }
            }
        }`,
      };
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });
      if(response?.data?.data?.getPOSWorkflowRules?.rules !== null && response?.data?.data?.getPOSWorkflowRules?.rules !== undefined && response?.data?.data?.getPOSWorkflowRules?.rules !== ""){
        await db.POSWorkFlowRules.bulkAdd(response?.data?.data?.getPOSWorkflowRules?.rules);
      }

      const approvers = response?.data?.data?.getPOSWorkflowRules?.approvers || [];

      if (approvers.length > 0) {
        // Generate unique ids for each approver
        const approversWithIds = approvers.map(approver => ({
          ...approver,
          id: uuidv4(), // Generate unique id
        }));
  
        // Add approvers with unique ids to IndexedDB
        await db.approvers.bulkAdd(approversWithIds);
  
        // const { approver } = response.data.data;
        // await db.POSWorkFlowRules.bulkAdd(response?.data?.data?.getPOSWorkflowRules?.approvers);
      }
      // const addOrUpdateRecord = async (tableName, record) => {
      //   await db[tableName].put(record);
      // };
      // for (const approver of response?.data?.data?.getPOSWorkflowRules?.approvers) {
      //   // Check if the record already exists in the store
      //   const existingRecord = await db.approvers.get(approver.csUserId);
      //   if (existingRecord) {
      //     // Record already exists, update it
      //     await addOrUpdateRecord("approvers", approver);
      //   } else {
      //     // Record doesn't exist, add it
      //     await db.approvers.add(approver);
      //   }
      // }      
  } catch (error) {
    console.log("Approvals: Sync Failed");
    throw error;
  }
};

const handlePosLogo = () => {
  // history.push("/dashboard");
};

const antIcon = <LoadingOutlined style={{ fontSize: data.syncStyles.fontSize }} spin />;

//   return (
//     <Spin
//       indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />}
//       spinning={loading}
//       style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
//     >
//       <div>Loading...</div>
//     </Spin>
//   );

// };

// export {syncProducts,syncPricingRules,syncRestaurantTables};
