import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

export const BuyXPayYOfSame = async (product, pricingRule, setCart, cart, orderType, cartObj) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const saleTypeValidation = pricingRule?.cwrSaletypeId === null || orderType === undefined ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletype?.cwrSaletypeId;
  let excludingFlag = true;

  const matchingCategoy = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === product.mProductCategoryId);

  if (
    (pricingRule.excludeProductCategories === "Y" && matchingCategoy) ||
    (pricingRule.excludeProductCategories !== "Y" && !matchingCategoy) ||
    (pricingRule.excludeProductCategories === "N" && pricingRule.mPricingPcategories.length === 0)
  ) {
    excludingFlag = false;
  }

  const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === product.productBrandId);

  if (
    (pricingRule.excludeBrands === "Y" && matchingBrand) ||
    (pricingRule.excludeBrands !== "Y" && !matchingBrand) ||
    (pricingRule.excludeBrands === "N" && pricingRule.mPricingBrands.length === 0)
  ) {
    excludingFlag = false;
  }

  const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);

  if (
    (pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) ||
    (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1) ||
    (pricingRule.excludeProducts === "N" && pricingRule.mPricingXProducts.length === 0)
  ) {
    excludingFlag = false;
  }

  let pricingRuleFlag = true;
  if (pricingRule.mPricingXProducts.length > 0) {
    pricingRule.mPricingXProducts.some((op) => {
      cartObj.items.map((product) => {
        if (op.mProductId === product.productId && product.nextRule === "Y") {
          pricingRuleFlag = false;
        }
      });
    });
  } else {
    if (product.nextRule === "Y") {
      pricingRuleFlag = false;
    }
  }

  if (saleTypeValidation && excludingFlag && pricingRuleFlag) {
    let addedToCart = [...cartObj.items]; // Create a copy of cartObj.items
    const offerProducts = pricingRule.mPricingXProducts;

    addedToCart.map((item) => {
      offerProducts.map((ele) => {
        if (ele.mProductId === item.productId) {
          item.discountName = "";
          item.mPricingruleId = null;
          item.discount = 0;
          item.discountType = "";
          item.freeItem = "N";
        }
      });
    });

    let uniqueItemsMap = {};
    addedToCart.forEach((item) => {
      const key = item.productId;
      if (!uniqueItemsMap[key] ) {
        uniqueItemsMap[key] = { ...item };
      } else {
        uniqueItemsMap[key].weight += item.weight;
        uniqueItemsMap[key].nettotal += item.nettotal;
        uniqueItemsMap[key].taxAmount += item.taxAmount;
        uniqueItemsMap[key].discount += item.discount;
      }
    });

    const uniqueItems = Object.values(uniqueItemsMap);

    // Convert back to array
    addedToCart = uniqueItems;

    addedToCart.forEach((ele) => {
      offerProducts.forEach((condProduct) => {
          if (ele.productId === condProduct.mProductId && !ele?.discountType && ele.weight >= pricingRule.xQty && !ele.mPricingruleId) {
              // Create a new item with remaining quantity
              let modValue = Math.floor(ele.weight / pricingRule.xQty)
              let discountProductQty = (pricingRule.xQty - pricingRule.yQty) * modValue;
              const discountAmt = ele.realPrice * discountProductQty;
              const sp = parseFloat(ele.realPrice * ele.weight) - discountAmt;
              const mrp = sp;
              const tax = mrp - mrp / (1 + ele.taxRate / 100);
              ele.taxAmount = tax;
              ele.offer = "Y";
              ele.nettotal = mrp;
              ele.promotionProduct = "Y";
              ele.discount = discountAmt;
              ele.discountName = pricingRule.printedName;
              ele.mPricingruleId = pricingRule.mPricingrulesId;
          }
      });
  });

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;
    for (let i = 0; i < addedToCart.length; i += 1) {
      totalPrice += addedToCart[i].nettotal;
      totalItemsQty += addedToCart[i].weight;
      totalTax += addedToCart[i].taxAmount;
      totalDiscounts += addedToCart[i].discount;
      addedToCart[i].key = i;
    }

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    totalPrice = roundOffValue;
    let finalCartObj = {
      ...cartObj,
      items: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
    };
    setCart(finalCartObj);
    localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
    return finalCartObj;
  }

}