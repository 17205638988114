import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

export const BuyXGetYDiscount = async (product, pricingRule, setCart, cart, orderType, cartObj) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const saleTypeValidation = pricingRule?.cwrSaletypeId === null || orderType === undefined ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletype?.cwrSaletypeId;
  let excludingFlag = true;

  const matchingCategoy = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === product.mProductCategoryId);

  if (
    (pricingRule.excludeProductCategories === "Y" && matchingCategoy) ||
    (pricingRule.excludeProductCategories !== "Y" && !matchingCategoy) ||
    (pricingRule.excludeProductCategories === "N" && pricingRule.mPricingPcategories.length === 0)
  ) {
    excludingFlag = false;
  }

  const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === product.productBrandId);

  if (
    (pricingRule.excludeBrands === "Y" && matchingBrand) ||
    (pricingRule.excludeBrands !== "Y" && !matchingBrand) ||
    (pricingRule.excludeBrands === "N" && pricingRule.mPricingBrands.length === 0)
  ) {
    excludingFlag = false;
  }

  const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);

  if (
    (pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) ||
    (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1) ||
    (pricingRule.excludeProducts === "N" && pricingRule.mPricingXProducts.length === 0)
  ) {
    excludingFlag = false;
  }

  let pricingRuleFlag = true;
  if (pricingRule.mPricingXProducts.length > 0) {
    pricingRule.mPricingXProducts.some((op) => {
      cartObj.items.map((product) => {
        if (op.mProductId === product.productId && product.nextRule === "Y") {
          pricingRuleFlag = false;
        }
      });
    });
  } else {
    if (product.nextRule === "Y") {
      pricingRuleFlag = false;
    }
  }

  if (saleTypeValidation && excludingFlag && pricingRuleFlag) {
    let addedToCart = [...cartObj.items]; // Create a copy of cartObj.items
    const offerProducts = pricingRule.mPricingXProducts;
    const freeProducts = pricingRule.mPricingXProducts.filter((prod) => prod.isFree === "Y");
    const conditionalProducts = pricingRule.mPricingXProducts.filter((prod) => prod.isFree === "N");
    const promotionCandidates = [];
    const unmatchedCandidates = [];

    addedToCart.map((item) => {
      offerProducts.map((ele) => {
        if (ele.mProductId === item.productId) {
          item.discountName = "";
          item.mPricingruleId = null;
          item.discount = 0;
          item.discountType = "";
          item.freeItem = "N";
        }
      });
    });

    let uniqueItemsMap = {};
    addedToCart.forEach((item) => {
      const key = item.productId;
      if (!uniqueItemsMap[key] ) {
        uniqueItemsMap[key] = { ...item };
      } else {
        uniqueItemsMap[key].weight += item.weight;
        uniqueItemsMap[key].nettotal += item.nettotal;
        uniqueItemsMap[key].taxAmount += item.taxAmount;
        uniqueItemsMap[key].discount += item.discount;
      }
    });

    const uniqueItems = Object.values(uniqueItemsMap);

    // Convert back to array
    addedToCart = uniqueItems;


    let matchedItems = [];
    addedToCart.forEach((ele) => {
        conditionalProducts.forEach((condProduct) => {
            if (ele.productId === condProduct.mProductId && !ele?.discountType && ele.weight >= condProduct.quantity && !ele.mPricingruleId) {
                let remainingQuantity = ele.weight;
                // Create a new item with remaining quantity
                for (let index = remainingQuantity; index >= condProduct.quantity; index -= condProduct.quantity) {
                    let newItem = { ...ele }; // Create a copy of the item
                    newItem.weight = condProduct.quantity; // Set the quantity to the conditional product quantity
                    remainingQuantity -= condProduct.quantity;
                    matchedItems.push(newItem); // Push the new item to matchedItems
                }
            }
        });
    });
    
    
  
    if (matchedItems.length > 0) {
      let uniqueMatchedItems = [];
      matchedItems.forEach((item) => {
        if (!uniqueMatchedItems.some((pushItem) => pushItem.productId === item.productId && pushItem.lineId === item.lineId && !pushItem.mPricingruleId)) {
          item = { ...item }; // Create a copy of the item to avoid modifying original data
          const mrp = parseFloat(item.realPrice) * item.quantity;
          const tax = mrp - mrp / (1 + item.taxRate / 100);
          item.taxAmount = tax;
          item.nettotal = mrp;
          item.discount = 0;
          item.weight = item.quantity;
          item.discountName = pricingRule.printedName;
          item.mPricingruleId = pricingRule.mPricingrulesId;
          item.discountType = "XGY";
          uniqueMatchedItems.push(item);
        }
      });


      let matchedFreeItems = [];

      addedToCart.forEach((ele) => {
          freeProducts.forEach((freeItem) => {
              if (ele.productId === freeItem.mProductId && !ele?.discountType && !ele.mPricingruleId) {
                  let remainingQuantity = ele.weight;
                  // Create a new item with remaining quantity
                  for (let index = remainingQuantity; index >= freeItem.quantity; index -= freeItem.quantity) {
                      let newItem = { ...ele }; // Create a copy of the item to avoid modifying original data
                      remainingQuantity -= freeItem.quantity;
                      newItem.discountType = "XGY";
                      newItem.freeItem = "Y";
                      const sp = 0;
                      const mrp = sp * newItem.weight;
                      const tax = mrp - mrp / (1 + newItem.taxRate / 100);
                      newItem.salePrice = sp;
                      newItem.taxAmount = tax;
                      newItem.nettotal = mrp;
                      newItem.weight = freeItem.quantity ? freeItem.quantity : 1;
                      newItem.discount = newItem.realPrice * newItem.weight;
                      newItem.discountName = pricingRule.printedName;
                      newItem.mPricingruleId = pricingRule.mPricingrulesId;
                      matchedFreeItems.push(newItem); // Push the new item to matchedItems
                  }
              }
          });
      });

      matchedItems.forEach((ele,ind) => {
        if(matchedFreeItems.length>0){
          let obj = {
            isFreeProductFlag: true,
            items: [matchedFreeItems[ind],matchedItems[ind]],
          };
          promotionCandidates.push(obj);
        }else if(matchedFreeItems.length >= ind + 1){
          const sp = matchedFreeItems[ind].realPrice;
          const mrp = sp * matchedFreeItems[ind].weight;
          const tax = mrp - mrp / (1 + matchedFreeItems[ind].taxRate / 100);
          matchedFreeItems[ind].salePrice = sp;
          matchedFreeItems[ind].taxAmount = tax;
          matchedFreeItems[ind].nettotal = mrp;
          matchedFreeItems[ind].discount = 0;
          matchedFreeItems[ind].discountName = "";
          matchedFreeItems[ind].mPricingruleId = null;
        }else if(matchedItems.length >= ind + 1){
          const fsp = matchedItems[ind].realPrice;
          const fmrp = fsp * matchedItems[ind].weight;
          const ftax = fmrp - fmrp / (1 + matchedItems[ind].taxRate / 100);
          matchedItems[ind].salePrice = fsp;
          matchedItems[ind].taxAmount = ftax;
          matchedItems[ind].nettotal = fmrp;
          matchedItems[ind].discount = 0;
          matchedItems[ind].discountName = "";
          matchedItems[ind].mPricingruleId = null;
        }
      })
    }

    promotionCandidates.forEach((promotionItems) => {
      if (promotionItems.isFreeProductFlag) {
        addedToCart = addedToCart.filter((item) => item !== null && item !== undefined);
        promotionItems.items = promotionItems.items.filter((item) => item !== null && item !== undefined);
        promotionItems.items.map((ele) => {
          const index = addedToCart.findIndex((item, ind) => {
            if(item){
              if (item.productId === ele.productId && ele.lineId === item.lineId) {
                return ind >= 0 ? true : false;
              }
            }
        });
        
          if (true) {
            const mrp = parseFloat(addedToCart[index].realPrice) * (addedToCart[index].weight - ele.weight);
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = 0;
            addedToCart[index].weight = addedToCart[index].weight - ele.weight;
            addedToCart[index].discountName = "";
            addedToCart[index].mPricingruleId = null;
            if (addedToCart[index].weight === 0) {
              delete addedToCart[index];
            }
          }
        });
        addedToCart = [...addedToCart, ...promotionItems.items];
      }
    });
    addedToCart = addedToCart.filter((item) => {
      if (item !== null && item !== undefined) {
        item.lineId = uuidv4().replace(/-/g, "").toUpperCase();
        const sp = item.realPrice;
        const mrp = sp * item.weight;
        const tax = mrp - mrp / (1 + item.taxRate / 100);
        item.salePrice = sp;
        item.taxAmount = tax;
        item.nettotal = mrp;
        return item;
      }
    });

    
    console.log(promotionCandidates, addedToCart, unmatchedCandidates);
    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;
    for (let i = 0; i < addedToCart.length; i += 1) {
      totalPrice += addedToCart[i].nettotal;
      totalItemsQty += addedToCart[i].weight;
      totalTax += addedToCart[i].taxAmount;
      totalDiscounts += addedToCart[i].discount;
      addedToCart[i].key = i;
    }

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    totalPrice = roundOffValue;
    let finalCartObj = {
      ...cartObj,
      items: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
    };
    setCart(finalCartObj);
    localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
    return finalCartObj;
  }
};
