import Dexie from "dexie";

const db = new Dexie("cwpos");
db.version(1)
  .stores({
    tillRegistrations: "++id",
    productCategories: "mProductCategoryId",
    productBrands: "brandId",
    products: "++id, mProductId, mProductCategoryId, value, name, upc, brandId, *batchIndex, *upcIndex",
    orders: "sOrderID, orderTime, documentno, isSynced, tillSessionId, customerSearchKey",
    pricingRules: "++id, billAmount, mPricingrulesId",
    posSaletypes: "++id, cwrSaletypeId",
    productUom: "++id, csUomId",
    tillEvents: "++id, tillSessionId, isSynced, tillStatus",
    logConfiguration: "++id",
    logInformation: "++id",
    restaurantTables:"++id",
    cashInCashOut:"++id",
    rfidData:"tag_value",
    restaurantTables:"++id",
    tableData:"cwrFbTableId, name, tableSync",
    fbOrderData:"++id,fbOrderId,cwrFbTableId,fbOrderStatus,fbOrderSync",
    sectionTables:"++id",
    loyalityData:"loyaltylevelId",
    AllProductCategories:"mProductCategoryId",
    POSWorkFlowRules:"cwrRulesId,ruleName,ruleCondition,eventId,eventName",
    approvers:"++id",
    giftCardData:"cwrGiftcardTypeId",
    paymentsData:"++id, tillSessionId, orderTime, tillStatus",
    ordersData:"++id, tillSessionId, orderTime, tillStatus",
  })
  .upgrade((tx) => {
    // Database Migrations
    console.log("DB Migration -> ", tx);
  });

export default db;
